import { combineReducers } from 'redux';
import globalReducer from './globalReducer';
import dashboardReducer from './dashboardReducer';
import productReducer from './productReducer';
import orderReducer from './orderReducer';
import userReducer from './userReducer';
import contactReducer from './contactReducer';
import reportReducer from './reportReducer'

export default combineReducers({
	global: globalReducer,
	dashboardR: dashboardReducer,
	productR: productReducer,
	orderR: orderReducer,
	userR: userReducer,
	contactR: contactReducer,
	reportR: reportReducer
});