import {
    REPORT_MONTHLY,
    REPORT_REFERRAL,
    REPORT_PRODUCT,
    CLEAR_REPORT
} from '../actions/types'

export const initialState = {
    monthly: null,
    referrals: null,
    products: null
}

export default (state = initialState, action) => {
    switch(action.type){
        case REPORT_MONTHLY :
            return {
                ...state,
                monthly: action.payload
            }
        case REPORT_REFERRAL:
            return {
                ...state,
                referrals: action.payload
            }
        case REPORT_PRODUCT :
            return {
                ...state,
                products: action.payload
            }
        case CLEAR_REPORT :
            return {
                ...state,
                monthly: null,
                referrals: null,
                products: null
            }
        default :
            return state
    }

}

