import React, {useEffect, Fragment} from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import OrderFilter from './OrderFilter'
import Pagination from '../utils/Pagination'
import DisplayingRecords from '../utils/DisplayingRecords'
import { formatCurrency, verify } from '../../helpers/Common'
import OrderStatusDropdown from './utils/OrderStatusDropdown'
import moment from 'moment'

import { getOrders, deleteOrder, clearCurrent } from '../../actions/orderActions'

const Orders = ({ getOrders, deleteOrder, clearCurrent, orderR: {orders, pagination, loading}, global:{currentUser, socket}}) => {
	
	useEffect(() => {
		getOrders();
		
		return () => {
			clearCurrent();
			socket.off('Orders Refresh')
		}
		
		// eslint-disable-next-line
	}, []);

	socket.off('Orders Refresh').on('Orders Refresh', (param) => {
		if(param.userId !== currentUser.userId){
			getOrders();
		}
	})

	const iclss = 'teal-text text-darken-2';
	const sclss = {fontSize:'14px', opacity:'0.8'};
	return (
		<div className="container col">

			<div className="row">
				<div className="col s6 m6">
					<h5 className="indigo-text text-darken-4 font-semibold">
						<i className="fa fa-file-invoice" ></i> Order History
					</h5>
				</div>
				<div className="col s6 m6">
					<br/>
					<div className="right">
						<Link to="/orders/form" className="waves-effect waves-light btn" onClick={clearCurrent} ><i className="material-icons left">add</i> New Order</Link>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col s8 m8"><OrderFilter /></div>
				<div className="col s4 m4 right-align"><DisplayingRecords data={pagination} /></div>
				<div className="col s12 m12">
					<div className="card white" style={{marginTop:0}}>
						<div>

							<table className="striped">
								<thead>
									<tr className="teal lighten-1 white-text">
										<th>ID</th>
										<th>Customer</th>
										<th>Shipping Address</th>
										<th>Order Details</th>
										<th className="right-align">Amount</th>
										<th className="center-align">Order Date</th>
										<th className="center-align">Status</th>
										<th>Referral</th>
										<th></th>
									</tr>
								</thead>
								<tbody>

								{ orders && orders.result.length ? orders.result.map(order => 
									<tr key={`order`+order.id} >
										<td className="top-align">{order.id}</td>
										<td className="top-align">
											{ order.customer_name }
											<div style={{...sclss, whiteSpace:'nowrap'}} ><i className={`fa fa-phone-square-alt ${iclss}`}></i> { order.contact_no }</div>
										</td>
										<td className="top-align"><span style={{fontSize:'14px'}}>{order.full_address}</span></td>
										<td className="top-align">
											<div style={{fontSize:'14px', minWidth:'260px'}}>
											{order.items.length  && 
											<Fragment>
												{order.items.length && order.items.map(item=>
													<div key={`ii${item.id}`} style={{paddingLeft:'16px'}} >
														<i className={`fa fa-clipboard-list ${iclss}`} style={{marginLeft:'-16px', marginRight:'5px', opacity:.8}}></i> 
														{item.name} 
														{item.qty > 1 && <strong> ({item.qty}x)</strong>}
													</div>
												)}
											</Fragment>}
											</div>

											<div style={sclss}>{order.remarks}</div>
										</td>
										<td className="top-align right-align"><span style={{fontSize:'14px'}}>{formatCurrency(order.total)}</span></td>
										<td className="top-align center-align">
											{ moment(order.order_date).format('ll') }
											<div style={{...sclss, whiteSpace:'nowrap'}} title="Delivery Date"><i className={`fa fa-shipping-fast ${iclss}`}></i> {moment(order.target_date).format('ll') }</div>
										</td>
										<td className="top-align center-align">
											<OrderStatusDropdown order={order} />
										</td>
										<td className="top-align">{order.referral}</td>
										<td className="top-align right-align" style={{whiteSpace:'nowrap'}}>
											<Link to={`/orders/edit/${order.id}`} className="waves-effect waves-light btn-small amber tooltipped" data-position="top" data-tooltip="Edit" ><i className="material-icons">edit</i></Link>
											&nbsp;
											<button className="waves-effect waves-light btn-small red lighten-1 tooltipped" data-position="top" data-tooltip="Delete" onClick={()=>verify('Are you sure you want to delete this?', ()=>{deleteOrder(order.id)})}><i className="material-icons" >delete</i></button> 

										</td>
									</tr>
								) : 
									<tr>
										<td colSpan="8">
											<div className="card yellow lighten-4 center-align padding15">No records found.</div>
										</td>
									</tr>	
								}
								</tbody>
							</table>

							
						</div>
						<div className="card-footer">
							<div className="row margin0">
								<div className="col s6 offset-s6">
									<div className="right">
										<Pagination data={pagination} paginate={(n)=>getOrders(n)} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


		</div>
	)
}

Orders.propTypes = {
	orderR: PropTypes.object.isRequired,
	getOrders: PropTypes.func.isRequired,
	deleteOrder: PropTypes.func.isRequired,
	clearCurrent: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
	global: state.global,
	orderR: state.orderR
});

export default connect(mapStateToProps, { getOrders, deleteOrder, clearCurrent})(Orders);
