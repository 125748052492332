import {deepMerge} from '../helpers/Common';
import M from 'materialize-css/dist/js/materialize.min.js';

import { 
	CURRENT_USER,
	UPDATE_ACCOUNT,
	SET_LOADING, 
	GET_DROPDOWN,
	DROPDOWN_ERROR,
	CLEAR_DROPDOWN,
	LOGIN,
	LOGIN_ERROR,
	LOGOUT,
	ERROR
} from './types';

// Set loading to true
export const setLoading = () => {
	return {type:SET_LOADING}
}


export const getQueryString = (params) => {
	var esc = encodeURIComponent;
	return Object.keys(params)
	  .map(k => esc(k) + '=' + esc(params[k]))
	  .join('&');
}

export const ajaxRequest = (url, data, method, extras) => {

	method = method || 'GET';
	data = data || {}

	if (process.env.NODE_ENV === 'production')
	{
		url = process.env.REACT_APP_API_URL+url;

		if (window.location.protocol === "https:") {
			url = url.replace('https:', "http:").replace('http:', "https:"); 
		}
	}
	
	var qs = '';
	var body;
	var headers =  {
	  'Accept': 'application/json',
	  'Content-Type': 'application/json',
	  'User-ID': localStorage.getItem('userId'),
	  'Auth-Token' : localStorage.getItem('token')
	};

	if (['GET', 'DELETE'].indexOf(method) > -1)
	  qs = '?' + getQueryString(data);
	else // POST or PUT
	  body = JSON.stringify(data);

	var params = { method, headers, body };

	if(typeof extras === 'object'){
		params = deepMerge([params, extras]);
	}

	return fetch(url+qs, params);
}

export const loadUser = () => async (dispatch) =>  {

	if(window.location.pathname === '/login')
	{
		return false;
	}

	// check authentication
	const res = await ajaxRequest('/api/secure/authentication', {
		userId: localStorage.getItem('userId'),
		token: localStorage.getItem('token')
	}, 'POST');
	const data = await res.json();


	if(data.current_user){
		//@TODO fetch current user
		dispatch({
			type: CURRENT_USER,
			payload: {
				...data.current_user,
				userId: localStorage.getItem('userId'),
				token: localStorage.getItem('token')
			}
		})
	}else{
		localStorage.clear();
		window.location = '/login';
	}
}

// Login
export const submitLogin = (login) => async (dispatch) => {
	try{
		dispatch(setLoading());

		const res = await ajaxRequest('/api/secure/login', login, 'POST');
		const data = await res.json();
		
		if(data.success === 1)
		{
			// set token
			localStorage.setItem('token', data.user_code);
			localStorage.setItem('userId', data.user_id);

			dispatch(loadUser());
		}

		dispatch({
			type: LOGIN,
			payload: data
		})


	}catch(err){
		dispatch({
			type: LOGIN_ERROR,
			payload: 'Something Went Wrong: submitLogin'
		})
	}
}

// Logout
export const logout = () => async () => {

	const res = await ajaxRequest('/api/secure/logout', {}, 'POST');
	const data = await res.json();

	if(data.success === 1)
	{
		localStorage.removeItem('userId');
		localStorage.removeItem('token');

		window.location = '/login';
	}

	return {
		type: LOGOUT
	}
}

// update current_user
export const updateAccount = (user) => async (dispatch) => {
	
	try {
		const res = await ajaxRequest('/api/secure/update-account', user, 'POST');
		const data = await res.json();

		dispatch({
			type: UPDATE_ACCOUNT,
			payload: data
		})

		if(data.success === 1)
		{
			M.Toast.dismissAll();
			M.toast({html: 'Account has been updated successfully.', 'classes':'green'});

			dispatch(loadUser());
		}
		

	} catch (error) {
		dispatch({
			type: ERROR,
			payload: 'Something Went Wrong: updateAccount'
		})
	}
}

// Load Dropdown Data
export const getDropdown = ({table, id, text, whereCol, whereVal}, force) => async (dispatch, getState) => {
	try{

		// generate key
		let key = table === 'options' ? 'options'+whereVal : table;
		
		if(typeof getState().global.dropdown[key] === 'undefined' || force){
			const res = await ajaxRequest(`/api/dropdown/get/${table}/${id || 'id'}/${text || 'name'}/${whereCol || 0}/${whereVal || 0}`);
			const data = await res.json();
	
			dispatch({
				type: GET_DROPDOWN,
				payload: {key, data: data.result}
			})

			return data.result
		}else{
			return getState().global.dropdown[key];
		}
		
	}catch(err){
		dispatch({
			type: DROPDOWN_ERROR,
			payload: 'Something Went Wrong: getDropdown'
		})
	}
}

// Clear dropdown data
export const clearDropdown = (table) => (dispatch)=> {

	dispatch({
		type: CLEAR_DROPDOWN,
		payload: table
	})
}
