import React, {useState, useEffect, Fragment} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";
import PerfectScrollbar from 'react-perfect-scrollbar'

import { getRecentOrders, clearRecentOrders } from '../../actions/orderActions';

import OrderStatusDropdown from '../orders/utils/OrderStatusDropdown'
import { formatCurrency } from '../../helpers/Common'
import moment from 'moment';

import 'react-perfect-scrollbar/dist/css/styles.css';

const RecentOrders = ({getRecentOrders, clearRecentOrders, orderR: {recentOrders}, global: {currentUser, socket}}) => {
	
	const history = useHistory();
	const limit = 4;
	const [page, setPage] = useState(1);
	const [items, setItems] = useState([]);

	useEffect(() => {
		getRecentOrders(0,limit);
		
		return () => {
			setItems([]);
			setPage(0);
			clearRecentOrders();

			socket.off('Orders Refresh');
		}
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if(recentOrders)
		{	
			var tmp = items.concat(recentOrders.result);
			setItems(tmp);
		}
		// eslint-disable-next-line
	}, [recentOrders])

	socket.on('Orders Refresh', (param) => {
		if(param.userId !== currentUser.userId){
			setItems([]);
			setPage(0);
			clearRecentOrders();

			setTimeout(()=>{
				getRecentOrders(0,limit);
			}, 0)
			
		}
	})
	
	const paginateRecentOrders = () => {
		
		var tmp = page+1;
		setPage(tmp);

		getRecentOrders(tmp, limit);
	}

	const iclss = 'teal-text text-darken-2';

	return (
		<div>
			<PerfectScrollbar id="recent_orders_wrapper" style={{height:'calc(100vh',marginTop:'22px'}}>
				<InfiniteScroll
					dataLength={items.length}
					next={(e)=>paginateRecentOrders(e)}
					hasMore={recentOrders && recentOrders.total_rows !== items.length ? true : false}
					loader={<div style={{paddingRight:'.75rem'}}><div className="progress" ><div className="indeterminate"></div></div></div>}
					scrollableTarget="recent_orders_wrapper"
				>
				<ul className="collection" style={{marginRight:'.75rem',marginTop:0}} >
				{items.map((order, index) => (
					 <li key={index} className="collection-item w1aves-effect wav1es-teal" 
					 onClick={()=>history.push('/orders/edit/'+order.id)} style={{display:'block'}}>
						
						<div className="row margin0 font-semibold" style={{marginBottom:'8px'}} >
							<div className="col s4 m4">
							#{order.id}
							</div>
							<div className="col s8 m8 right-align ">{formatCurrency(order.total)}</div>
						</div>
						
						<div style={{fontSize:'14px'}}>
							<div className="font-semibold"><i className={`fa fa-user ${iclss}`} ></i> {order.customer_name}</div>
							<div><i className={`fa fa-shipping-fast ${iclss}`} ></i> {order.full_address}</div>
							
							<div title="Order Summary" style={{margin:'8px 0'}}>

								<div style={{fontSize:'14px'}}>
								{order.items.length  && 
								<Fragment>
									{order.items.length && order.items.map(item=>
										<div key={`ii-${item.id}`} style={{paddingLeft:'16px'}} >
											<i className={`fa fa-clipboard-list ${iclss}`} style={{marginLeft:'-16px', marginRight:'5px'}}></i> 
											{item.name} 
											{item.qty > 1 && <strong> ({item.qty}x)</strong>}
										</div>
									)}
								</Fragment>}
								</div>

							</div>
							<div style={{margin:'8px 0'}}>{order.remarks_short}</div>
							<OrderStatusDropdown order={order}  />
							<hr/>
							<div>
								<span style={{opacity:0.9}}>Order Date </span> 
								<div className="right">{moment(order.order_date).format('ll')}</div>
							</div>
							<div>
								<span style={{opacity:0.9}}>Delivery Date</span> 
								<div className="right">{moment(order.target_date).format('ll (ddd)')}</div>
							</div>
							<div>
								<span style={{opacity:0.9}}>Referral </span> 
								<div className="right">{order.referral}</div>
							</div>
						</div>
					</li>
				))}
				</ul>
				</InfiniteScroll>

			</PerfectScrollbar>
		</div>
	)
}

RecentOrders.propTypes = {
	getRecentOrders: PropTypes.func.isRequired,
	clearRecentOrders: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
	global: state.global,
	orderR: state.orderR
})

export default connect(mapStateToProps, {getRecentOrders, clearRecentOrders})(RecentOrders)
