import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {getDropdown} from '../../../actions/globalActions'

import {updateOrderStatus} from '../../../actions/orderActions'
import {verify} from '../../../helpers/Common'
import M from 'materialize-css/dist/js/materialize.min.js'

const OrderStatusDropdown = ({getDropdown, updateOrderStatus, global:{dropdown}, order}) => {

    const [statusDD, setStatusDD] = useState()
    const [currentStatus, setCurrentStatus] = useState({
        status_slug: order.status_slug, 
        status_name: order.status_name
    })
    const {status_slug, status_name} = currentStatus;

    useEffect(() => {
      
        var result = getDropdown({table:"options", whereCol: 'category_id', whereVal:1})
        result.then((res)=>{
            setStatusDD(res)
        })

        var elem = document.getElementById(`os-${order.id}`);
        M.Dropdown.init(elem, {constrainWidth:false});
        
        // eslint-disable-next-line
    }, [])

    const onClick = (status) =>{
        if(status === order.status) {
            return false
        }
        verify('Are you sure you want to update the status?', () =>{
           var res = updateOrderStatus(order.id, status)
           res.then((res)=>{
                if(res.success){
                   let {status_slug, status_name} = res.row;

                    setCurrentStatus({
                        status_slug,
                        status_name
                    })
                }
               
           })
        })
    }

    return (
        <div onClick={(e)=>{ e.stopPropagation()}}>
            <a id={`os-${order.id}`} className={`order_status ${status_slug}`} href='#!' data-target={`os-${order.id}-dd`} >{status_name}</a>
            <ul id={`os-${order.id}-dd`} className='dropdown-content'>
                <li><span className=" grey-text">Update Status </span></li>
                <li className="divider"></li>
                {statusDD && statusDD.map(row => 
                    <li key={row.val} value={row.val} >
                        <span onClick={()=>onClick(row.val)} className={(row.val === order.status ? 'grey-text' :'')} >{row.text}</span>
                    </li>
                )}
            </ul>
        </div>
    )
}

const mapStateToProps = state => ({
    global: state.global
})

export default connect(mapStateToProps, {getDropdown, updateOrderStatus})(OrderStatusDropdown)
