import { setLoading, ajaxRequest, clearDropdown } from './globalActions';
import M from 'materialize-css/dist/js/materialize.min.js';

import {
	GET_USERS,
	SAVE_USER,
	VIEW_USER,
	CLEAR_USER,
	USER_ERROR
} from './types';

// Get list of users
export const getUsers = (page=0) => async (dispatch) => {
	try {
		dispatch(setLoading());

		const res = await ajaxRequest('/api/users/list/0/'+page);
		const data = await res.json();

		dispatch({
			type: GET_USERS,
			payload: data
		});

	} catch (err) {
		dispatch({
			type: USER_ERROR,
			payload: err.response.message
		})
	}

}

// Save user
export const saveUser = (user) => async (dispatch) => {
	try {
		dispatch(setLoading());

		const res = await ajaxRequest('/api/users/save', user, 'POST');
		const data = await res.json();

		dispatch({
			type: SAVE_USER,
			payload: data
		})

		if(data.success === 1)
		{
			// reload products
			dispatch(getUsers());
			
			// close modal
			var userForm = document.getElementById("userForm");
			var userFormModal = M.Modal.getInstance(userForm);
			userFormModal.close();
		}
	} catch (err) {
		dispatch({
			type: USER_ERROR,
			payload: 'Something Went Wrong: saveUser'
		})
	}

}

export const editUser = (id) => async (dispatch) => {
	try {
		const res = await ajaxRequest(`/api/users/view/${id}`);
		const data = await res.json();

		if(data.row)
		{
			dispatch({
				type: VIEW_USER,
				payload: data
			})
		}
	} catch(err) {
		dispatch({
			type: USER_ERROR,
			payload: 'Something Went Wrong: editUser'
		})
	}
}

export const deleteUser = (id) => async (dispatch) => {
	try {
		
		M.toast({html: 'Deleting...'})
		const res = await ajaxRequest(`/api/users/index/${id}`, {}, 'DELETE');
		const data = await res.json();

		if(data.success === 1)
		{
			dispatch(clearDropdown('users'))

			// reload users
			dispatch(getUsers());
			M.Toast.dismissAll();
			M.toast({html: 'Delete Success.'});
		}

	} catch (error) {
		dispatch({
			type: USER_ERROR,
			payload: 'Something Went Wrong: deleteUser'
		})
	}
}

export const clearCurrent = () => {
	return {type:CLEAR_USER}
}
