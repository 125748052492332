import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { saveProduct } from '../../actions/productActions';

import M from 'materialize-css/dist/js/materialize.min.js';

const ProductForm = ({saveProduct, productR: { error_array, current }}) => {
	
	const productFields = {
		name: '',
		description: '',
		price: ''
	};

	// lesson 49
	const [product, setProduct] = useState(productFields);
	const { name, description, price } = product;

	useEffect(() => {
		
		M.AutoInit(document.getElementById("productFormWrapper"));
		setProduct(productFields);	
		
		if(current){
			var productForm = document.getElementById("productForm");
			var productFormModal =M.Modal.getInstance(productForm);

			setProduct(current.row);
			productFormModal.open();

			setTimeout(()=>{
				M.updateTextFields();
			},0)
		}
		
		// eslint-disable-next-line
	}, [current]);
	

	const onChange = (e) => {
		
		if(e.target.name === 'price'){
			e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
		}

		setProduct({
			...product, 
			[e.target.name]:e.target.value
		})
	};

	const onSubmit = () => {
		saveProduct(product);
		
		if(Object.keys(error_array).length === 0){
			setProduct(productFields);	
		}
	}


	return (
		<div id="productFormWrapper">
			<div id="productForm" className="modal modal-sm">
		
				<form className="col s12">
				<div className="modal-content">
					<h4 className="indigo-text darken-3 font-semibold">
						<i className="material-icons middle-align" >all_inbox</i>Product Form
					</h4>
				
					<div className="row">
					
						<div className="input-field col s12">
							<input id="frm_prod_name" name="name" type="text" className={ 'validate' + (error_array.name ? ' invalid' : '')} value={name} onChange={onChange} />
							<label htmlFor="frm_prod_name">Name</label>
							<span className="helper-text" data-error={error_array.name}></span>
						</div>
						<div className="input-field col s12">
							<textarea id="frm_prod_desc" name="description" className="materialize-textarea validate" value={description} onChange={onChange} ></textarea>
							<label htmlFor="frm_prod_desc">Description</label>
						</div>
						<div className="input-field col s12">
							<input id="frm_prod_price" name="price" type="text" className={ 'validate' + (error_array.price ? ' invalid' : '')} value={price} onChange={onChange} float="1" />
							<label htmlFor="frm_prod_price">Price</label>
							<span className="helper-text" data-error={error_array.price}></span>
						</div>
					</div>

				</div>
				<div className="modal-footer">
					<a href="#!" className="modal-close waves-effect waves-green btn-flat">Cancel</a>
					<a href="#!" onClick={onSubmit} className=" waves-effect waves-blue btn" >Save</a>
				</div>
				</form>
			</div>
		</div>
	)
}

ProductForm.propTypes = {
	saveProduct: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
	productR: state.productR,
});

export default connect(mapStateToProps, {saveProduct})(ProductForm)
