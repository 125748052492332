import { setLoading, ajaxRequest, clearDropdown } from './globalActions';
import M from 'materialize-css/dist/js/materialize.min.js';

import { 
	GET_PRODUCTS,
	SAVE_PRODUCT,
	VIEW_PRODUCT,
	CLEAR_PRODUCT,
	PRODUCT_ERROR
} from './types';


// Get records from server
export const getProducts = (page=0) => async (dispatch) => {
	try {
		dispatch(setLoading());
	
		const res = await ajaxRequest('/api/products/list/0/'+page);
		const data = await res.json();

		dispatch({
			type: GET_PRODUCTS,
			payload: data
		});
	}catch(err){
		dispatch({
			type: PRODUCT_ERROR,
			payload: err.response.message
		})
	}
}

// Add Product
export const saveProduct = (product) => async (dispatch, getState) => {
	try{
		dispatch(setLoading());

		const res = await ajaxRequest('/api/products/save', product, 'POST');
		const data = await res.json();

		dispatch({
			type: SAVE_PRODUCT,
			payload: data
		})

		if(data.success === 1)
		{
			dispatch(clearDropdown('products'))

			// reload products
			dispatch(getProducts());
			
			// close modal
			var productForm = document.getElementById("productForm");
			var productFormModal = M.Modal.getInstance(productForm);
			productFormModal.close();
		}

	}catch(err){
		dispatch({
			type: PRODUCT_ERROR,
			payload: 'Something Went Wrong: saveProduct'
		})
	}
}

// Delete Product
export const deleteProduct = (id) => async (dispatch) => {
	try {

		M.toast({html: 'Deleting...'})
		const res = await ajaxRequest(`/api/products/index/${id}`, {}, 'DELETE');
		const data = await res.json();

		if(data.success === 1)
		{
			dispatch(clearDropdown('products'))

			// reload products
			dispatch(getProducts());
			M.Toast.dismissAll();
			M.toast({html: 'Delete Success.'});
		}
	} catch(err) {
		dispatch({
			type: PRODUCT_ERROR,
			payload: 'Something Went Wrong: deleteProduct'
		})
	}
}

export const editProduct = (id) => async (dispatch) => {
	try {
		const res = await ajaxRequest(`/api/products/view/${id}`);
		const data = await res.json();

		if(data.row)
		{
			dispatch({
				type: VIEW_PRODUCT,
				payload: data
			})
		}
	} catch(err) {
		dispatch({
			type: PRODUCT_ERROR,
			payload: 'Something Went Wrong: editProduct'
		})
	}
}

export const clearCurrent = () => {
	return {type:CLEAR_PRODUCT}
}
