import React, {useEffect} from 'react'
import {connect} from 'react-redux';
import {getStats} from '../../actions/dashboardActions'

const Stats = ({getStats, dashboardR: {orderStats}, global:{socket}}) => {

    useEffect(() => {
        
        getStats()
        return () => {
            
        }
    }, [])

    socket.on('Orders Refresh', (param) => {
        setTimeout(()=>{
            getStats()
        }, 0)
	})
	

    return (
        <div className="row margin0">
            
            <div className="col m4">
                <div className="card teal lighten-2 white-text">
                    <div className="card-content">
                       <div className="row" style={{marginBottom:0}}>
                            <div className="col s4 center-align">
                                <h3 className="margin0"><i className="fa fa-file-alt"></i></h3>
                            </div>
                            <div className="col s8">
                                <h5 className="margin0 font-semibold">{orderStats.new_orders || 0}</h5>
                                <div>New Orders</div>
                                <div style={{lineHeight:1}}><small>(7 days)</small></div>
                           </div>
                       </div>
                    </div>
                </div>
            </div>
            <div className="col m4">
                <div className="card red lighten-2 white-text">
                    <div className="card-content">
                       <div className="row" style={{marginBottom:0}}>
                            <div className="col s4 center-align">
                                <h3 className="margin0"><i className="fa fa-history"></i></h3>
                            </div>
                            <div className="col s8">
                                <h5 className="margin0 font-semibold">{orderStats.pending || 0}</h5>
                                <div>Pending Orders</div>
                                <div style={{lineHeight:1}}><small>&nbsp;</small></div>
                           </div>
                       </div>
                    </div>
                </div>
            </div>
            <div className="col m4">
                <div className="card green white-text">
                    <div className="card-content">
                        <div className="row" style={{marginBottom:0}}>
                            <div className="col s4 center-align">
                                <h3 className="margin0"><i className="fa fa-truck"></i></h3>
                            </div>
                            <div className="col s8">
                                <h5 className="margin0 font-semibold">{orderStats.for_delivery || 0}</h5>
                                <div>For Delivery</div>
                                <div style={{lineHeight:1}}><small>(This week)</small></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}


const mapStateToProps = state => ({
    global: state.global,
    dashboardR: state.dashboardR,
    
})

export default connect(mapStateToProps, {getStats})(Stats)
