
import {
	GET_USERS,
	SAVE_USER,
	VIEW_USER,
	CLEAR_USER
} from '../actions/types';

const initialState = {
	users : null,
	current: null,
	loading: false,
	pagination: null,
	error: null,
	error_array: {},
}

export default (state = initialState, action) => {
	switch(action.type){

		case GET_USERS:
			return {
				...state,
				users: action.payload,
				pagination : action.payload.pagination,
				loading:false
			}
		case CLEAR_USER:
			return {
				...state,
				current: null,
				loading:false
			}
		case VIEW_USER:
			return {
				...state,
				current: action.payload
			}
		case SAVE_USER:
			return {
				...state,
				error_array: (typeof action.payload.error_array !== 'undefined' ? action.payload.error_array : {}),
				loading: false,
			}
		default :
			return state;
	}
}