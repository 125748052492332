import { 
	GET_ORDERS,
	GET_RECENT_ORDERS,
	FILTER_ORDER,
	SAVE_ORDER,
	ORDER_ERROR,
	VIEW_ORDER,
	CLEAR_ORDER,
	CLEAR_RECENT_ORDER
} from '../actions/types';

const initialState = {
	orders: null,
	recentOrders: null,
	current: null,
	filters: null,
	loading: false,
	pagination: null,
	error: null,
	error_array: {},
}

export default (state = initialState, action) => {
	switch(action.type){
		case GET_ORDERS:

			return {
				...state,
				orders: action.payload,
				pagination: action.payload.pagination,
				loading:false
			}
		case GET_RECENT_ORDERS:

			return {
				...state,
				recentOrders: action.payload,
				pagination: action.payload.pagination,
				loading:false
			}
		case ORDER_ERROR:
			return {
				...state,
				error: action.payload,
				loading: false
			}
		case VIEW_ORDER:
			return {
				...state,
				current: action.payload
			}
		case SAVE_ORDER:
			return {
				...state,
				error_array: (typeof action.payload.error_array !== 'undefined' ? action.payload.error_array : {}),
				current: (typeof action.payload.row !== 'undefined' ? action.payload : null),
				loading: false,
			}
		
		case FILTER_ORDER:
			return {
				...state,
				filters: action.payload
			}
		case CLEAR_ORDER:
			return {
				...state,
				current: null
			}		
		case CLEAR_RECENT_ORDER:
			return {
				...state,
				recentOrders: null
			}		
		default:
			return state;
	}

}