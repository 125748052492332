import { setLoading, ajaxRequest } from './globalActions';
import M from 'materialize-css/dist/js/materialize.min.js';

import {
	GET_CONTACTS,
	SAVE_CONTACT,
	CONTACT_ERROR,
	VIEW_CONTACT,
	CLEAR_CONTACT
} from './types'

// get contacts
export const getContacts = (page=0) => async (dispatch) => {
	try {
		dispatch(setLoading())
		const res = await ajaxRequest('/api/contacts/list/0/'+page);
		const data = await res.json();

		dispatch({
			type: GET_CONTACTS,
			payload: data
		})
	} catch (error) {
		dispatch({
			type: CONTACT_ERROR,
			payload: 'Something Went Wrong: getContacts'
		})
	}
}

export const saveContact = (contact) => async (dispatch) => {
	try {
		dispatch(setLoading())

		const res = await ajaxRequest('/api/contacts/save/', contact, 'POST');
		const data = await res.json();

		dispatch({
			type: SAVE_CONTACT,
			payload: data
		})

		if(data.success === 1)
		{
			// reload products
			dispatch(getContacts());
			
			// close modal
			var productFormModal = M.Modal.getInstance(document.getElementById("contactForm"));
			productFormModal.close();
		}

	} catch (error) {
		
	}
}

export const editContact = (id) => async (dispatch) => {
	try {
		dispatch(setLoading())
		const res = await ajaxRequest('/api/contacts/view/'+id);
		const data = await res.json();

		dispatch({
			type: VIEW_CONTACT,
			payload: data
		})
	} catch (error) {
		dispatch({
			type: CONTACT_ERROR,
			payload: 'Something Went Wrong: editContact'
		})
	}

}

export const deleteContact = (id) => async (dispatch) => {
	try {
		M.toast({html: 'Deleting...'})
		const res = await ajaxRequest(`/api/contacts/index/${id}`, {}, 'DELETE');
		const data = await res.json();

		if(data.success === 1)
		{
			// reload products
			dispatch(getContacts());
			M.Toast.dismissAll();
			M.toast({html: 'Delete Success.'});
		}
	} catch (error) {
		dispatch({
			type: CONTACT_ERROR,
			payload: 'Something Went Wrong: deleteContact'
		})
	}
}

export const clearCurrent = () => {
	return {type: CLEAR_CONTACT}
}