export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const CURRENT_USER = 'CURRENT_USER';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const GET_DROPDOWN = 'GET_DROPDOWN';
export const CLEAR_DROPDOWN = 'CLEAR_DROPDOWN';
export const DROPDOWN_ERROR = 'DROPDOWN_ERROR';

export const SET_LOADING = 'SET_LOADING';
export const SET_CURRENT = 'SET_CURRENT';
export const CLEAR_CURRENT = 'CLEAR_CURRENT';
export const ERROR = 'ERROR';

export const GET_DASHBOARD_CALENDAR = 'GET_DASHBOARD_CALENDAR';
export const GET_DASHBOARD_STATS = 'GET_DASHBOARD_STATS';

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const SAVE_PRODUCT = 'SAVE_PRODUCT';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';
export const VIEW_PRODUCT = 'VIEW_PRODUCT';
export const CLEAR_PRODUCT = 'CLEAR_PRODUCT';

export const GET_ORDERS = 'GET_ORDERS';
export const GET_RECENT_ORDERS = 'GET_RECENT_ORDERS';
export const FILTER_ORDER = 'FILTER_ORDER';
export const SAVE_ORDER = 'SAVE_ORDER';
export const ORDER_ERROR = 'ORDER_ERROR';
export const VIEW_ORDER = 'VIEW_ORDER';
export const CLEAR_ORDER = 'CLEAR_ORDER';
export const CLEAR_RECENT_ORDER = 'CLEAR_RECENT_ORDER';

export const GET_USERS = 'GET_USERS';
export const SAVE_USER = 'SAVE_USER';
export const USER_ERROR = 'USER_ERROR';
export const VIEW_USER = 'VIEW_USER';
export const CLEAR_USER = 'CLEAR_USER';

export const GET_CONTACTS = 'GET_CONTACTS';
export const SAVE_CONTACT = 'SAVE_CONTACT';
export const CONTACT_ERROR = 'CONTACT_ERROR';
export const VIEW_CONTACT = 'VIEW_CONTACT';
export const CLEAR_CONTACT = 'CLEAR_CONTACT';

export const REPORT_MONTHLY = 'REPORT_MONTHLY';
export const REPORT_REFERRAL = 'REPORT_REFERRAL';
export const REPORT_PRODUCT = 'REPORT_PRODUCT';
export const REPORT_ERROR = 'REPORT_ERROR';
export const CLEAR_REPORT = 'CLEAR_REPORT';