import React from 'react'
import { useHistory } from 'react-router-dom';

const PageNotFound = () => {
	const history = useHistory();
	
	return (
		
		<div className="container">
			<br/><br/>
			
			<div className="row">
				<div className="col s12 m6 offset-s3 offset-m3">
					<div className="card white">
						<div className="card-content white-text">
							<div className="center-align">
								<h2 className="indigo-text">404</h2>
								<h4 className="indigo-text">Page not found.</h4>
								<button className="btn amber waves-effect waves-light"
								onClick={() => {history.goBack()}}>Go Back</button>
								<br/><br/>
			
							</div>
						</div>
					
					</div>
				</div>
			</div>
		</div>
	)
}

export default PageNotFound
