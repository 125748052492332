import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { updateAccount } from '../../actions/globalActions'

import { className } from '../../helpers/Common';

import M from 'materialize-css/dist/js/materialize.min.js';

const AccountForm = ({updateAccount, global:{currentUser, error_array}}) => {

	const [user, setUser] = useState({name:'', new_password: '', confirm_new_password:''});
	const {name, new_password, confirm_new_password} = user;

	useEffect(() => {
		
		// na init sa iba
		var elem = document.getElementById('accountModal');
		var instance = M.Modal.getInstance(elem);
		if(instance){
			instance.destroy();
		}
	
		M.Modal.init(elem, {
			onOpenEnd: () => {

				var elems = document.querySelectorAll('#accountModal .tooltipped');
				M.Tooltip.init(elems);
	 
				var user = {...currentUser};
				
				user.new_password = '';
				user.confirm_new_password = '';

				setUser(user)
				M.updateTextFields();
			}
		});

	}, [currentUser])

	const onChange = (e) => {
		setUser({
			...user,
			[e.target.name]: e.target.value
		})
	}

	const onSubmit = (e) => {
		e.preventDefault();
		updateAccount(user)
	}

	return (
		<div id="accountModal" className="modal modal-sm" >
			<form onSubmit={onSubmit} className="col s12">
				<div className="modal-content">
					<h4 className="indigo-text font-semibold"><i className="fa fa-user-circle"></i> My Account</h4>
					<br/>
					<div className="row">
						<div className="col s6 m6">
							<div className="input-field">
								<input id="frm_am_username" type="text" 
								className="tooltipped" data-position="left" data-tooltip="Username can't be changed"
								readOnly={true} defaultValue={currentUser.username} />
								<label htmlFor="frm_am_username">Username</label>
							</div>
						</div>
						<div className="col s6 m6">
							<div className="input-field">
								<input id="frm_am_name" type="text" name="name" 
								className={className('name', error_array)} 
								onChange={onChange} value={name}  />
								<label htmlFor="frm_am_name">Name</label>
								<span className="helper-text" data-error={error_array.name}></span>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col s6 m6">
							<div className="input-field">
								<input id="frm_am_password" type="password" name="new_password" 
								className={className('new_password', error_array)} 
								onChange={onChange} value={new_password} />
								<label htmlFor="frm_am_password">Change Password</label>
								<span className="helper-text" data-error={error_array.new_password}></span>
							</div>
						</div>
						<div className="col s6 m6">
							<div className="input-field">
								<input id="frm_am_confirm_password" type="password" name="confirm_new_password" className={className('confirm_new_password', error_array)} 
								onChange={onChange} value={confirm_new_password} />
								<label htmlFor="frm_am_confirm_password">Confirm New Password</label>
								<span className="helper-text" data-error={error_array.confirm_new_password}></span>
							</div>
						</div>
					</div>
					
				</div>
				<div className="modal-footer">
					<button type="button" className="modal-close btn waves-effect waves-teal grey">Close</button> &nbsp;
					<button type="submit" className="waves-effect waves-teal btn" >Update</button> 
				</div>

			</form>
		</div>
	)
}

AccountForm.propTypes = {
	updateAccount: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
	global: state.global
})

export default connect(mapStateToProps, {updateAccount})(AccountForm)
