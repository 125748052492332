import React from 'react'

const DisplayingRecords = ({data}) => {

	if(data !== null && data.total_rows > 0)
	{
		let tmp_from = parseInt(data.offset) + 1;
		let tmp_to = parseInt(data.offset) + parseInt(data.limit);
		tmp_to = (tmp_to > data.total_rows ? data.total_rows : tmp_to);

		return <p> Displaying <strong>{tmp_from} - {tmp_to} of {data.total_rows}</strong> Results.</p>;
	
	}

	return '';
}


export default DisplayingRecords
