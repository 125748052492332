import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';

import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import "react-datepicker/dist/react-datepicker.css";
import 'alertifyjs/build/css/alertify.min.css'

import PrivateRoute from './components/utils/PrivateRoute';
import PrivateComponent from './components/utils/PrivateComponent';

import Header from './components/layouts/Header';
import AccountForm from './components/users/AccountForm'
import Login from './components/pages/LoginForm';

import Dashboard from './components/dashboard/Dashboard'
import Orders from './components/orders/Orders';
import OrderForm from './components/orders/OrderForm';
import Customers from './components/customers/Customers';
import Products from './components/products/Products';
import Users from './components/users/Users';
import Contacts from './components/contacts/Contacts'
import Reports from './components/reports/Reports'

import PageNotFound from './components/layouts/PageNotFound'

import './App.css';

const App = () => {

	useEffect(() => {
		// Init Materialize JS
		M.AutoInit();
		
		// todo dapat may props yung PrivateRoute, dito dapat nag loload user
	});

  return (
		<Provider store={store}>
			<Router>
				<div className="App">
					<PrivateComponent component={Header} />
					<Switch>
						<Route exact path='/login' component={Login}></Route>
						<PrivateRoute exact path='/' component={Dashboard}></PrivateRoute>
						<PrivateRoute exact path='/orders' component={Orders}></PrivateRoute>
						<PrivateRoute exact path='/orders/form' component={OrderForm}></PrivateRoute>
						<PrivateRoute exact path={`/orders/edit/:orderId`} component={OrderForm}></PrivateRoute>

						<PrivateRoute exact path='/customers' component={Customers}></PrivateRoute>

						<PrivateRoute exact path='/products' component={Products}></PrivateRoute>
						<PrivateRoute exact path='/contacts' component={Contacts}></PrivateRoute>
						<PrivateRoute exact path='/reports' component={Reports}></PrivateRoute>						

						<PrivateRoute exact path='/users' component={Users}></PrivateRoute>
						<Route component={PageNotFound} />
					</Switch>
					<PrivateComponent component={AccountForm} />
				</div>
			</Router>
		</Provider>
  );
}

export default App;
