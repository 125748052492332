import { setLoading, ajaxRequest } from './globalActions';
import M from 'materialize-css/dist/js/materialize.min.js';

import { 
	GET_ORDERS,
	GET_RECENT_ORDERS,
	FILTER_ORDER,
	SAVE_ORDER,
	VIEW_ORDER,
	CLEAR_ORDER,
	ORDER_ERROR,
	CLEAR_RECENT_ORDER,
	SET_CURRENT
} from './types';


// Get records from server
export const getOrders = (page) => async (dispatch, getState) => {
	try {
		dispatch(setLoading());

		var limit = 15;
		page = page || 0;

		const res = await ajaxRequest('/api/orders/list/0/'+limit+'/0/0/'+page, getState().orderR.filters)
		const data = await res.json();

		dispatch({
			type: GET_ORDERS,
			payload: data
		});
	}catch(err){
		console.log({err})
		dispatch({
			type: ORDER_ERROR,
			payload: err.response.message
		})
	}
}

// Get Recent order from server
export const getRecentOrders = (page=0, limit=4) => async (dispatch) => {
	try {
		dispatch(setLoading());
		
		const res = await ajaxRequest('/api/orders/list/0/'+limit+'/0/0/'+page);
		const data = await res.json();

		dispatch({
			type: GET_RECENT_ORDERS,
			payload: data
		});
	}catch(err){
		
		dispatch({
			type: ORDER_ERROR,
			payload: err.response.message
		})
	}
}

// Add Order
export const saveOrder = (order, save_exit) => async (dispatch, getState) => {
	try{
		dispatch(setLoading());
		
		// clear error_array
		getState().orderR.error_array = {};
		M.toast({html: 'Saving...', 'classes':'amber lighten-1 black-text'})
		const res = await ajaxRequest('/api/orders/save', order, 'POST');
		const data = await res.json();

		dispatch({
			type: SAVE_ORDER,
			payload: data
		})
		
		if(data.success === 1){
			M.Toast.dismissAll();
			M.toast({html: 'Order has been saved successfully.', 'classes':'green'});

			// reload orders
			//dispatch(getOrders());

			return {data, save_exit};

		}else{
			M.Toast.dismissAll();
			M.toast({html: 'Something is wrong...', 'classes':'red'})
		}
		
		return {data, save_exit};
	}catch(err){
		dispatch({
			type: ORDER_ERROR,
			payload: 'Something Went Wrong: saveOrder'
		})
	}
}

// Update Status
export const updateOrderStatus = (id, status) => async (dispatch) =>{
	try {
		
		const res = await ajaxRequest(`/api/orders/status/${id}`, {status}, 'PUT');
		const data = await res.json();

		console.log({data})
		return data;
	} catch (error) {
		dispatch({
			type: ORDER_ERROR,
			payload: 'Something Went Wrong: updateOrderStatus'
		})
	}
}

// Delete Order
export const deleteOrder = (id) => async (dispatch, getState) => {
	try {

		M.toast({html: 'Deleting...'})
		const res = await ajaxRequest(`/api/orders/index/${id}`, {}, 'DELETE');
		const data = await res.json();

		if(data.success === 1)
		{
			// reload orders
			dispatch(getOrders());
			M.Toast.dismissAll();
			M.toast({html: 'Delete Success.'});


			const {socket, currentUser } = getState().global;
			socket.emit('Orders Refresh', {userId: currentUser.userId})
		}
	} catch(err) {
		dispatch({
			type: ORDER_ERROR,
			payload: 'Something Went Wrong: deleteOrder'
		})
	}
}

export const editOrder = (id) => async (dispatch) => {
	try {
		M.toast({html: 'Loading...', 'classes':'amber lighten-1 black-text'})
		const res = await ajaxRequest(`/api/orders/view/${id}`);
		const data = await res.json();

		if(data.row)
		{
			M.Toast.dismissAll();
			dispatch({
				type: VIEW_ORDER,
				payload: data
			})

			// trigger the dropdown
			var event = new Event('change', { bubbles: true });
			var elem = document.querySelector('select[name="shipping_province_id"]');
			elem.dispatchEvent(event);
		}

		return data;
	} catch(err) {
		dispatch({
			type: ORDER_ERROR,
			payload: 'Something Went Wrong: editOrder'
		})
	}
}

export const filterOrder = (filters) => (dispatch) => {
	dispatch({
		type:FILTER_ORDER,
		payload:filters
	})
}


export const clearCurrent = () => {
	return {type:CLEAR_ORDER}
}

export const clearRecentOrders = () => {
	return {type:CLEAR_RECENT_ORDER}
}