import React, {useEffect} from 'react'
import {connect} from 'react-redux'

import {clearReport} from '../../actions/reportActions'
import Monthly from './Monthly'
import Products from './Products'
import Referrals from './Referrals'

const Reports = ({clearReport}) => {

    useEffect(() => {
      

        return () => {
            clearReport();
        }
    }, [])

    return (
        <div className="container col">
            <div className="row">
                <div className="col s6 m6">
                    <h5 className="indigo-text text-darken-4 font-semibold">
                        <i className="fa fa-chart-bar" ></i> Reports
                    </h5>
                </div>
            </div>

            <Monthly />
            <br/>
            <div className="row">
                <div className="col s6">
                    <Products />
                </div>
                <div className="col s6">
                    <Referrals />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    reportR: state.reportR
})

export default connect(mapStateToProps, {clearReport})(Reports)
