import {
	GET_DASHBOARD_CALENDAR,
	GET_DASHBOARD_STATS
} from '../actions/types'

const initialState = {
	orderEvents: {},
	orderStats: {}
}

export default (state = initialState, action) => {
	
	switch(action.type){
		case GET_DASHBOARD_CALENDAR:
			return {
				...state,
				orderEvents: (typeof action.payload.orders !== 'undefined' ? action.payload.orders : {})
			}
		case GET_DASHBOARD_STATS:
			
			return {
				...state,
				orderStats: action.payload
			}
		default: 
			return state;
	}


}
