import React from 'react'
import {connect} from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({global:{currentUser}, component: Component,  ...rest }) => {

	const loggedIn = localStorage.getItem('userId') ? true : false;

	return (
		<Route
		{...rest}
		render={props => (
			loggedIn
			? <Component {...props} />
			: <Redirect to="/login" />
		)}
		/>
	)
}

const mapStateToProps = state => ({
	global: state.global,
});

export default connect(mapStateToProps, {})(PrivateRoute)
