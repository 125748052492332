import { formatMoney } from 'accounting-js';
import alertify from 'alertifyjs';


// Deep merge object
export const deepMerge = (objects) => {
	let target = {};
	// Merge the object into the target object
	let merger = (obj) => {
		 for (let prop in obj) {
			if (obj.hasOwnProperty(prop)) {
			  if (Object.prototype.toString.call(obj[prop]) === '[object Object]'){
					// If we're doing a deep merge 
					// and the property is an object
					target[prop] = deepMerge([target[prop], obj[prop]]);
					console.log([target[prop], obj[prop]]);
				  } else {
					// Otherwise, do a regular merge
					target[prop] = obj[prop];
				  }
			}
		}
	};
	//Loop through each object and conduct a merge
	for (let i = 0; i < objects.length; i++) {
		merger(objects[i]);
	}
	
	return target;
};
//==============

export const className = (field, error_array, classes) => {
	return 'validate' + (error_array[field] ? ' invalid' : '') + ' '+(classes ||'')
}

export const formatCurrency = (amount) => {

	return formatMoney(amount, {
		symbol: 'Php '
	})
}

export const verify = (message, confirmCallback, deniedCallback, title) => {
	alertify.defaults.theme.ok = "teal btn";
	alertify.defaults.theme.cancel = "blue-grey lighten-1 btn";

	title = '<span class="font-semibold">'+(title || 'Please Confirm')+'</span>';

	alertify.confirm(title, message, function(){
		if (typeof confirmCallback === "function"){
			confirmCallback();
		}
	}, function(){
		if (typeof deniedCallback === "function"){
			deniedCallback();
		}
	});
}

