import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import FormDropdown from '../utils/FormDropdown'
import { className } from '../../helpers/Common';
import M from 'materialize-css/dist/js/materialize.min.js';

import {saveUser} from '../../actions/userActions'

const UserForm = ({saveUser, userR:{current, error_array}}) => {

	const userFields = {
		name: '',
		username: '',
		group_id: 2,
		active: 1,
		new_password: '',
		confirm_new_password: '',
	}

	const [user, setUser] = useState(userFields)
	const {name, username, group_id, active, new_password, confirm_new_password} = user

	useEffect(() => {
		
		M.AutoInit(document.getElementById("userFormWrapper"));
		setUser(userFields);	
		
		if(current){
			var userForm = document.getElementById("userForm");
			var userFormModal =M.Modal.getInstance(userForm);

			setUser(current.row);
			userFormModal.open();

			setTimeout(()=>{
				M.updateTextFields();
			},0)
		}
		
		// eslint-disable-next-line
	}, [current]);

	const onChange = (e) => {
		setUser({
			...user,
			[e.target.name]: e.target.value
		})
	}

	const onSubmit = (e) => {
		e.preventDefault();
		saveUser(user);
		
		if(Object.keys(error_array).length === 0){
			setUser(userFields);	
		}
	}

	return (
		<div id="userFormWrapper">
			<div id="userForm" className="modal modal-sm">
		
				<form className="col s12">
				<div className="modal-content">
					<h4 className="indigo-text darken-3 font-semibold">
						<i className="fa fa-user" ></i> User Form
					</h4>
				
					<div className="input-field" style={{marginBottom:0}}>
						<input id="frm_usr_name" name="name" type="text" className={className('name', error_array)} value={name} onChange={onChange} />
						<label htmlFor="frm_usr_name">Name</label>
						<span className="helper-text" data-error={error_array.name}></span>
					</div>

					<div className="row margin0">
					
						<div className="input-field col s6 m6">
							<input id="frm_usr_username" name="username" type="text" 
								className={className('username', error_array)} 
								value={username} onChange={onChange} />
							<label htmlFor="frm_usr_username">Username</label>
							<span className="helper-text" data-error={error_array.username}></span>
						</div>
						<div className="input-field col s6 m6">
							<FormDropdown name="group_id" remoteParam={{table:"groups"}} 
							className={className('group_id', error_array)} 
							onChange={onChange} value={group_id} />
							<label>Role</label>
							<span className="helper-text" data-error={error_array.group_id}></span>
						</div>
					</div>

					<div className="row margin0">
						<div className="col s6 m6">
							<div className="input-field">
								<input id="frm_usr_password" type="password" name="new_password" 
								className={className('new_password', error_array)} 
								onChange={onChange} value={new_password || ''} />
								<label htmlFor="frm_usr_password">Change Password</label>
								<span className="helper-text" data-error={error_array.new_password}></span>
							</div>
						</div>
						<div className="col s6 m6">
							<div className="input-field">
								<input id="frm_usr_confirm_password" type="password" name="confirm_new_password" className={className('confirm_new_password', error_array)} 
								onChange={onChange} value={confirm_new_password || ''} />
								<label htmlFor="frm_usr_confirm_password">Confirm Password</label>
								<span className="helper-text" data-error={error_array.confirm_new_password}></span>
							</div>
						</div>
					</div>

					<div className="row margin0">
						<div className="col s6 m6">
							<div className="input-field">
							
								<FormDropdown name="active" options={[{val:0, text:'Inactive'}, {val:1, text:'Active'}]} 
									className={className('active', error_array)} 
									onChange={onChange} value={active} />
								<label>Role</label>
								<span className="helper-text" data-error={error_array.active}></span>
						
							</div>
						</div>
					</div>
				</div>
				<div className="modal-footer">
					<a href="#!" className="modal-close waves-effect waves-green btn-flat">Cancel</a>
					<button type="button" onClick={onSubmit} className="waves-effect waves-blue btn" >Save</button>
				</div>
				</form>
			</div>
		</div>
	)
}

const mapStateToProps = state => ({
	userR: state.userR
})

export default connect(mapStateToProps, {saveUser})(UserForm)
