import { ajaxRequest } from './globalActions';
import {
    REPORT_MONTHLY,
    REPORT_REFERRAL,
    REPORT_PRODUCT,
    REPORT_ERROR,
    CLEAR_REPORT
} from './types'


export const monthlyReport = () => async (dispatch) => {

    try { 
        const res = await ajaxRequest('/api/reports/monthly', {}, 'GET');
        const data = await res.json();

        dispatch({
            type: REPORT_MONTHLY,
            payload: data
        })

    } catch (error) {
        dispatch({
			type: REPORT_ERROR,
			payload: 'Something Went Wrong: monthlyReport'
		})
    }
}


export const referralReport = () => async (dispatch) => {

    try {
        
        const res = await ajaxRequest('/api/reports/referrals', {}, 'GET');
        const data = await res.json();
console.log({data})

        dispatch({
            type: REPORT_REFERRAL,
            payload: data
        })

    } catch (error) {
        dispatch({
			type: REPORT_ERROR,
			payload: 'Something Went Wrong: referralReport'
		})
    }

}

export const productReport = () => async (dispatch) => {

    try {
        
        const res = await ajaxRequest('/api/reports/products', {}, 'GET');
        const data = await res.json();

        dispatch({
            type: REPORT_PRODUCT,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: REPORT_ERROR,
            payload: 'Something Went Wrong: productReport'
        })
    }

}

export const clearReport = () => {
    return {type:CLEAR_REPORT}
}