import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import {productReport} from '../../actions/reportActions'

const Products = ({productReport, reportR:{products}}) => {

    useEffect(() => {
        
        if(products === null)
        {
            productReport();
        }

        return () => {
        
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className="card white" style={{marginTop:0}}>
            <table className="striped">
                <thead>
                    <tr className="teal lighten-1 white-text">
                        <th>Product</th>
                        <th>QTY</th>
                    </tr>
                </thead>
                <tbody>
                    {products && products.data.map(row => 
                    <tr key={row.id}>
                        <td>{row.name}</td>
                        <td>{row.total_qty}</td>
                    </tr>
                    )}
                </tbody>

            </table>
        </div>
    )
}

const mapStateToProps = state => ({
    reportR: state.reportR
})

export default connect(mapStateToProps, {productReport})(Products)
