import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import { referralReport } from '../../actions/reportActions'

import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const Referrals = ({referralReport, reportR: {referrals}}) => {

    useEffect(() => {
        
        if(referrals === null)
        {
            referralReport();
        }
       

        return () => {
        
        }

        // eslint-disable-next-line
    }, [referrals])

    if(referrals !== null)
    {
        let names = [];
        let total_orders = [];
        let total_cancelled = [];
        console.log({data: referrals.data})
        referrals.data.map(row => {
            names.push(row.name)
            total_orders.push((parseInt(row.order) ? parseInt(row.order) : 0 ))
            total_cancelled.push((parseInt(row.cancelled) ? parseInt(row.cancelled) : 0 ))
        })

        const options = {
            chart: {
                type: 'bar'
            },
            title: {
                text: 'Referrals Report'
            },
            subtitle: {
               //text: 'Source: <a href="https://en.wikipedia.org/wiki/World_population">Wikipedia.org</a>'
            },
            xAxis: {
                categories: names,
                title: {
                    text: null
                }
            },
            yAxis: {
                title: {
                    text: 'Orders',
                },
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                x: -40,
                y: 80,
                floating: true,
                borderWidth: 1,
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                shadow: true
            },
            credits: {
                enabled: false
            },
            series: [{
                name: 'Total Orders',
                data: total_orders
            }, {
                name: 'Cancelled Orders',
                data: total_cancelled
            }]
        }
    

        return (
            <div className="card white" style={{marginTop:0}}>
               
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
        )
    }

    return <div></div>
}

const mapStateToProps = state => ({
    reportR:state.reportR
})

export default connect(mapStateToProps, {referralReport})(Referrals)
