import React, {useState, useEffect, Fragment} from 'react'
import FormDropdown from '../utils/FormDropdown'
import { formatCurrency } from '../../helpers/Common'


const OrderFormItems = ({order, setOrder}) => {

	var itemFields = {
		product_id:'',
		qty:1,
		price:'',
		total:''
	}
	
	const [items, setItems] = useState([itemFields]);
	const [total, setTotal] = useState({subtotal:0, total:0});
	const shipping_fee = parseFloat(order.shipping_fee || 0);

	useEffect(() => {
		
		if(order.items && order.items.length){
			setItems(order.items);
		}

		var tmp = calculateTotal(items);
		setTotal(tmp);
		
		// eslint-disable-next-line 
	}, [order.items, order.shipping_fee, items])

	const onChange = (index, e) => {
		const values = [...items];

		//e.target.attributes['float']
		if(e.target.attributes['float']){
			e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
		}

		values[index][e.target.name] = e.target.value;

		// calculate row total
		values[index]['total'] = parseFloat(values[index]['price'] || 0) * parseFloat(values[index]['qty'] || 0);

		setItems(values);
		var tmp = calculateTotal(values);
		
		setOrder({
			...order,
			items:values,
			subtotal: tmp.subtotal,
			total: tmp.total
		})
	}

	const addRemoveRow = (index) => {
		var values = [...items];

		// remove row
		if(typeof index !== 'undefined'){
			values.splice(index, 1);
		}else{
			values.push(itemFields);
		}

		setItems(values);
		
		var tmp = calculateTotal(values);
		setTotal(tmp);
		setOrder({
			...order,
			items:values,
			subtotal: tmp.subtotal,
			total: tmp.total
		})
	}

	const calculateTotal = (items) => {
		
		var subtotal = 0;
		for(const item of items){
			subtotal += parseFloat(item.total || 0)
		}
		
		var tmp_total = subtotal + shipping_fee;
		
		return {subtotal, total:tmp_total}
	}

	
	return (
		<table className="striped">
			<thead>
				<tr className="orange lighten-2 white-text">
					<th width="50"></th>
					<th>Items  </th>
					<th className="center-align" width="60">Qty</th>
					<th className="right-align" width="150">Price</th>
					<th className="right-align" width="150">Total</th>
				</tr>
			</thead>
			<tbody>
				{items.map((item, index) => (
            <Fragment key={`item~${index}`} >
				<tr>
					<td> 
						{items.length === index + 1 ? 
						<a href="#!" className="btn-floating btn-small waves-effect waves-light teal add-row" onClick={() => addRemoveRow()} >
							<i className="material-icons">add</i>
						</a>
						:
						<a href="#!" className="btn-floating btn-small waves-effect waves-light red remove-row" onClick={() => addRemoveRow(index)} >
							<i className="material-icons">remove</i>
						</a>
						}
					</td>
					<td>
						<div className="input-field margin0">
							<FormDropdown id={`product-${index}`} name="product_id" remoteParam={{table:"products"}} onChange={e => onChange(index, e)} value={item.product_id} />
							<span className="helper-text"></span>
						</div>
					</td>
					<td className=" center-align">
						<div className="input-field margin0">
							<input id={`qty-${index}`} name="qty" type="text" className={ 'center-align validate' } onChange={e => onChange(index, e)} value={item.qty} float="1" />
							<label htmlFor="frm_cust_name"></label>
							<span className="helper-text"></span>
						</div>
					</td>
					<td className="right-align">
						
						<div className="input-field margin0">
							<span className="currency prefix">Php</span>
							<input type="text" name="price" className="validate" onChange={e => onChange(index, e)} value={item.price} float="1" />
							<span className="helper-text"></span>
						</div>
						
					</td>
					<td className="right-align">
						{formatCurrency(item.total)}
					</td>
				</tr>
				</Fragment>
				))}
			</tbody>
			<tfoot>
				<tr>
					<td colSpan="3" className="top-align padding5">
						<div className="input-field ">
							<textarea id="remarks" name="remarks" className="materialize-textarea" 
							value={order.remarks || ''}
							onChange={(e) => setOrder({
								...order, 
								[e.target.name]:e.target.value
							})}
							></textarea>
							<label htmlFor="remarks">Note</label>
						</div>
					</td>
					<td className="right-align top-align">
						<div>Subtotal</div>
						<div style={{margin:'0 0 8px'}}>Shipping Fee</div>
						<div className="font-semibold">Total</div>
					</td>
					<td className="right-align top-align">
						<div>{formatCurrency(total.subtotal)}</div>
						<div style={{margin:'0 0 8px'}}>{formatCurrency(shipping_fee)}</div>
						<div className="font-semibold">{formatCurrency(total.total)}</div>
					</td>
				</tr>
				
				
			</tfoot>
		</table>
	)
}

export default OrderFormItems
