import { 
	LOGIN,
	UPDATE_ACCOUNT,
	CURRENT_USER,
	SET_LOADING,
	GET_DROPDOWN,
	CLEAR_DROPDOWN
} from '../actions/types';

import io from "socket.io-client";
const ENDPOINT = 'localhost:4001'

const initialState = {
	token: localStorage.getItem('token'),
	loading: false,
	currentUser: false,
	error_array: {},
	dropdown: {},
	socket: io(ENDPOINT, {
		autoConnect:false // this will disable the real time
	})
}

initialState.socket.on('disconnect', (reason) => {
	if (reason === 'io server disconnect') {
		// the disconnection was initiated by the server, you need to reconnect manually
		//initialState.socket.connect();
	}
	console.log('Ay nadisconnect')
	// else the socket will automatically try to reconnect
});

export default (state = initialState, action) => {
	
	switch(action.type) {
		case SET_LOADING:
			return {
				...state,
				loading: true
			}
		case LOGIN:
			return {
				...state,
				error_array: (typeof action.payload.error_array !== 'undefined' ? action.payload.error_array : {}),
				loading: false
			}
		case CURRENT_USER:
			return {
				...state,
				currentUser: action.payload,
				loading: false
			}
		case UPDATE_ACCOUNT:
			return {
				...state,
				error_array: (typeof action.payload.error_array !== 'undefined' ? action.payload.error_array : {}),
				loading: false
			}
		case GET_DROPDOWN:
		
			state.dropdown[action.payload.key] = action.payload.data;
			return {
				...state,
				dropdown : state.dropdown
			}
		case CLEAR_DROPDOWN:
			var dropdown = state.dropdown;
			
			delete dropdown[action.payload];
			
			return {
				...state,
				dropdown: state.dropdown
			}
		default: 
			return state;
	}
}