import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import FormDropdown from '../utils/FormDropdown'
import OrderFormItems from './OrderFormItems'
import {saveOrder, editOrder, clearCurrent} from '../../actions/orderActions';
import DatePicker from 'react-datepicker'
import { className } from '../../helpers/Common';
import M from 'materialize-css/dist/js/materialize.min.js';

const OrderForm = ({match, saveOrder, editOrder, clearCurrent, global:{currentUser, dropdown, socket}, orderR:{error_array, current}}) => {

	const history = useHistory();
	const [goBack, setGoBack] = useState(0);

	const [order, setOrder] = useState({
		customer_name:'',
		contact_no:'',
		order_date: new Date(),
		shipping_address: '',
		shipping_province_id: '',
		shipping_fee:'',
		shipping_city_id: '', target_date : '',
		status: 1
	});

	const {customer_name, order_date, contact_no, user_id, shipping_province_id, shipping_city_id, target_date, shipping_fee, shipping_address, status} = order;

	useEffect(() => {
		if(match.params.orderId){
			editOrder(match.params.orderId)
		}

		return () => {
			clearCurrent()
			setGoBack(0)
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
	
		setTimeout(()=>{
			M.updateTextFields();
		},0)

		if(currentUser && ! user_id){
			setOrder((o) =>{ 
				o.user_id = currentUser.userId;
				return o 
			})
		}

		if(current){
			current.row['order_date'] = new Date(current.row.order_date);
			current.row['target_date'] = new Date(current.row.target_date);

			setOrder(current.row)
		}

		// eslint-disable-next-line
	}, [currentUser, current])

	const onChange = (e) => {

		//e.target.attributes['float']
		if(e.target.attributes['float']){
			e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
		}

		setOrder({
			...order, 
			[e.target.name]:e.target.value
		})
	};


	const onSubmit = (save_exit) => {

		var submit = saveOrder(order, save_exit)
		submit.then((res)=>{
			if(res.data.success === 1){
				socket.emit('Orders Refresh', {userId: currentUser.userId})
				if(res.save_exit === 1){
					//history.push('/orders')
					setGoBack(1)
				}
			}
		})
	}

	if(goBack)
	{
		history.goBack();
	}

	return (
		<div className="container col">
	
			<div className="row">
				<div className="col s6 m6">
					<h5 className="indigo-text text-darken-4 font-semibold">
					{match.params.orderId ? <span><i className="fa fa-pencil-alt"></i> Edit</span> : <span><i className="fa fa-plus-circle"></i> New</span>} Order</h5>
				</div>
				<div className="col s6 m6 right-align">
					<button id="goBack" onClick={() => history.goBack() } className="waves-effect waves-blue btn teal darken-2" style={{marginTop:'22px'}}>
						<i className="material-icons">chevron_left</i> 
						<span style={{verticalAlign:'top'}}>Back</span>
					</button>
				</div>
			</div>
				
			<form onSubmit={onSubmit}>
				<div className="row">

					<div className="col l4">
					
						<div className="card white">
							<div className="card-content">

								<h5 className="teal-text"><i className="fa fa-user-circle"></i> Customer Details</h5>
								<br/>
								<div className="input-field ">
									<input id="frm_cust_name" name="customer_name" type="text" 
									className={'validate' + (error_array.customer_name ? ' invalid' : '')} 
									value={customer_name} onChange={onChange} />
									<label htmlFor="frm_cust_name">Name</label>
									<span className="helper-text" data-error={error_array.customer_name}></span>
								</div>

								<div className="input-field ">
									<input id="frm_cust_contact" name="contact_no" type="text" 
									className={className('contact_no', error_array)} 
									value={contact_no} onChange={onChange} />
									<label htmlFor="frm_cust_contact">Contact No.</label>
									<span className="helper-text" data-error={error_array.contact_no}></span>
								</div>

							</div>
							
						</div>

						<div className="card white">
							<div className="card-content">

								<h5 className="teal-text"><i className="fa fa-info-circle"></i> Other Details</h5>
								<br/>

								<div className="input-field ">
									<span className={className('order_date', error_array, 'dp_wrapper')}>
									<DatePicker id="frmDP_order_date" name="order_date" selected={order_date} 
									className={className('order_date', error_array, 'dp_wrapper')}
									dateFormat="yyyy/MM/dd" value={order_date} 
									onChange={(date)=>setOrder({...order, order_date: date})} autoComplete="off" />
									</span>
									
									<label htmlFor="frmDP_order_date" className="active">Order Date</label>
									<span className="helper-text" data-error={error_array.order_date}></span>
								</div>
								
								<div className="row">
									<div className="col s6 m6">
										<div className="input-field">
											<FormDropdown name="user_id" remoteParam={{table:"users"}} 
											className={className('user_id', error_array)} 
											onChange={onChange} value={user_id} />
											<label>Referral</label>
											<span className="helper-text" data-error={error_array.user_id}></span>
										</div>
									</div>

									<div className="col s6 m6">
										<div className="input-field">
											<FormDropdown name="status" remoteParam={{table:"options", whereCol: 'category_id', whereVal:1}} 
											className={className('user_id', error_array)} 
											onChange={onChange} value={status} />
											<label>Status</label>
											<span className="helper-text" data-error={error_array.status}></span>
										</div>
									</div>
								</div>
								
							</div>
							
						</div>

					</div>

					<div className="col l8">
					
						<div className="card white">
							<div className="card-content">
								<h5 className="teal-text"><i className="fa fa-clipboard-list"></i> Order Details</h5>
								<br/>
								<OrderFormItems order={order} setOrder={setOrder} />

								<br/>
								<h5 className="teal-text"><i className="fa fa-shipping-fast"></i> Shipping Details</h5>
								<br/>

								<div className="row">
									<div className="col s12 m8">
										
										<div className="input-field ">
											<input id="frm_ordr_address" name="shipping_address" type="text" 
											className={className('shipping_address', error_array)} 
											onChange={onChange} value={shipping_address} />
											<label htmlFor="frm_ordr_address">Address</label>
											<span className="helper-text" data-error={error_array.shipping_address}></span>
										</div>

										<div className="row">
											<div className="col s12 m6">

												<div className="input-field">
													<FormDropdown name="shipping_province_id" 
													remoteParam={{table:"phil_provinces", id:'provCode'}} 
													relation={{table:'phil_cities', id:'citymunCode', whereCol:'provCode'}}
													className={className('shipping_province_id', error_array)} 
													onChange={onChange} 
													value={shipping_province_id} 
													/>
													<label>Province</label>
													<span className="helper-text" data-error={error_array.shipping_province_id}></span>
												</div>

											</div>

											<div className="col s12 m6">

												<div className="input-field">
													<FormDropdown name="shipping_city_id" 
													options={dropdown['phil_cities']} 
													onChange={onChange} value={shipping_city_id} 
													className={className('shipping_city_id', error_array)} 
													/>
													<label>City</label>
													<span className="helper-text" data-error={error_array.shipping_city_id}></span>
												</div>
											</div>

										</div>
									</div>
									<div className="col s12 m4">
										
										<div className="input-field">
											<span className="currency prefix">Php</span>
											<input id="frm_shipping_fee" type="text" name="shipping_fee" 
											className={className('shipping_fee', error_array)} 
											value={shipping_fee}	onChange={onChange} float="1" />
											<label htmlFor="frm_shipping_fee">Shipping Fee</label>
											<span className="helper-text" data-error={error_array.shipping_fee}></span>
										</div>
										
										<div className="row">
											<div className="col s12 right-align">
												<div className="input-field ">
													<i className="material-icons prefix">date_range</i>

													<span className={className('target_date', error_array, 'dp_wrapper')}>
													<DatePicker id="frmDP_target_date" name="target_date" selected={target_date} 
													className={className('target_date', error_array, 'dp_wrapper '+(target_date ? 'active' :''))}
													dateFormat="yyyy/MM/dd" value={target_date} 
													onChange={(date)=>setOrder({...order, target_date: date})} autoComplete="off" />
													</span>
													<label htmlFor="frmDP_target_date" className={target_date ? 'active' : ''}>Delivery Date</label>
													<span className="helper-text" data-error={error_array.target_date}></span>
												</div>
											</div>
										</div>


									</div>
								</div>
							</div>
						</div>

						<div className="right-align">
							<a href="#!" onClick={() => {onSubmit(0)}} className=" waves-effect waves-blue btn" >Save</a> &nbsp;
							<a href="#!" onClick={() => {onSubmit(1)}} className=" waves-effect waves-blue btn" >Save & Exit</a>
						</div>
					</div>

				</div>
			</form>
		</div>
	)
}

OrderForm.propTypes = {
	saveOrder: PropTypes.func.isRequired,
	editOrder: PropTypes.func.isRequired,
	clearCurrent: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
	global: state.global,
	orderR: state.orderR
});

export default connect(mapStateToProps, {saveOrder, editOrder,clearCurrent})(OrderForm)
