import React from 'react'
import {connect} from 'react-redux';

const PrivateComponent = ({global:{currentUser}, component: Component,  ...props }) => {

	const loggedIn = localStorage.getItem('userId') ? true : false;

	return (loggedIn ? <Component {...props} />: '')
}

const mapStateToProps = state => ({
	global: state.global,
});

export default connect(mapStateToProps, {})(PrivateComponent)
