import { setLoading, ajaxRequest } from './globalActions';

import {
	GET_DASHBOARD_CALENDAR,
	GET_DASHBOARD_STATS,
	ERROR
} from './types'

// get order for calendar
export const getOrderEvents = (param) => async (dispatch) => {
	try{
		dispatch(setLoading())
		
		const res = await ajaxRequest('/api/orders/calendar/', param, 'POST');
		const data = await res.json();
		
		dispatch({
			type: GET_DASHBOARD_CALENDAR,
			payload: data
		});

		return data;

	}catch(err){
		dispatch({
			type: ERROR,
			payload: 'Something Went Wrong: submitLogin'
		})
	}
}

// get stats
export const getStats = () => async (dispatch) => {
	try{
		dispatch(setLoading())

		const res = await ajaxRequest('/api/orders/stats/');
		const data = await res.json();

		dispatch({
			type: GET_DASHBOARD_STATS,
			payload: data
		});

		return data;

	}catch(err){
		dispatch({
			type: ERROR,
			payload: 'Something Went Wrong: getStats'
		})
	}
	
}