import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import ContactForm from './ContactForm'
import {getContacts, editContact, deleteContact, clearCurrent} from '../../actions/contactActions'
import DisplayingRecords from '../utils/DisplayingRecords'
import Pagination from '../utils/Pagination'
import {verify} from '../../helpers/Common'

const Contacts = ({getContacts, editContact, deleteContact, clearCurrent, contactR:{contacts, pagination}, global: {currentUser}}) => {

	useEffect(() => {
		
		getContacts();

		return () => {
			clearCurrent()
		}
	
		// eslint-disable-next-line
	}, [])

	return (
		<div className="container col">
			<ContactForm />
			<div className="row">
				<div className="col s6 m6">
					<h4 className="indigo-text darken-3 font-semibold">
					<i className="fa fa-id-card" ></i> Contacts
					</h4>
				</div>
				<div className="col s6 m6 right-align">
					<br/>
					<a href="#contactForm" className="waves-effect waves-light btn modal-trigger" onClick={()=> clearCurrent()}><i className="material-icons left">add</i> Contact</a>
				
				</div>
			</div>
			<div className="row">
				<div className="col s12 m12">
					<div className="right-align">
						<DisplayingRecords data={pagination} />
					</div>
					<div className="card white">
						<div>
							<table className="striped">
								<thead>
									<tr className="teal lighten-1 white-text">
										<th>ID</th>
										<th>Name</th>
										<th>Description</th>
										<th>Phone (Work)</th>
										<th>Phone (Private)</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{ contacts === null ? <tr></tr> : contacts.result.map(contact => 
									<tr key={`contact`+contact.id} >
										<td className="top-align">{contact.id}</td>
										<td className="top-align">{contact.name}</td>
										<td className="top-align">{contact.description}</td>
										<td className="top-align">
											{ contact.phone_work && JSON.parse(contact.phone_work).map((val,k)=>
												<div key={`pw`+k}><i className="fa fa-phone-alt"></i> {val}</div>
											)}
										</td>
										<td className="top-align">
											{ contact.phone_private && JSON.parse(contact.phone_private).map((val,k)=>
												<div key={`pp`+k}><i className="fa fa-phone-alt"></i> {val}</div>
											)}
										</td>
										<td className="top-align right">
										
											<div>
												<a href="#contactForm" className="waves-effect waves-light btn-small amber tooltipped " data-position="top" data-tooltip="Edit" onClick={()=>editContact(contact.id)} ><i className="material-icons" >edit</i></a> 
												&nbsp;
												<button className="waves-effect waves-light btn-small red lighten-1 tooltipped" data-position="top" data-tooltip="Delete" onClick={()=>verify('Are you sure you want to delete this?', ()=>deleteContact(contact.id)) }><i className="material-icons"  >delete</i></button> 
											</div>
										
										</td>
									</tr>)}
								</tbody>
							</table>
						</div>
						<div className="card-footer">
							<div className="row margin0">
								<div className="col s6 offset-s6">
									<div className="right">
										<Pagination data={pagination} paginate={(n)=>getContacts(n)} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	)
}

const mapStateToProps = state => ({
	global: state.global,
	contactR: state.contactR
})

export default connect(mapStateToProps, {getContacts, editContact, deleteContact, clearCurrent})(Contacts)
