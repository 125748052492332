

import { formatCurrency } from '../../helpers/Common'
import moment from 'moment';

export const CalendarPopover = (order) => {
	
	const iclss = 'teal-text text-darken-2';

	return (`
		<div class="card teal lighten-5" style="width:350px;margin:-9px -7px;">
			<div class="card-content left-align black-text col">
				<span class="card-title">#${order.id}</span>
			
				<div class="row margin0" style="margin-bottom:8px">
					<div class="col s6 m6">
						<div><i class="fa fa-user ${iclss}"></i> ${order.customer_name}</div>
					</div>
					<div class="col s6 m6 right-align">
						<div><i class="fa fa-phone ${iclss}"></i> ${order.contact_no}</div>
					</div>
				</div>
				<div><i class="fa fa-shipping-fast ${iclss}"></i> ${order.full_address}</div>
				<br />
				<div title="Order Summary">
					<i class="fa fa-clipboard-list ${iclss}" ></i> ${order.summary}
				</div>
				<div>${order.remarks}</div>
				<br />
				
				<div>
					<span style="opacity:0.9">Order Date:</span> 
					${moment(order.order_date).format('ll')}
				</div>
				<div>
					<span style="opacity:0.9">Delivery Date:</span> 
					${moment(order.target_date).format('ll (ddd)')}
				</div>
				<div>
					<span style="opacity:0.9">Status:</span> 
					<span class="order_status ${order.status_slug} ">${order.status_name}</span>
				</div>
				
			</div>
			<div class="card-action left-align black-text col">
				<div class="row margin0">
					<div class="col s6 m6">
						<i class="fa fa-user-tie ${iclss}"></i> ${order.referral}
					</div>
					<div class="col s6 m6 right-align font-semibold">${formatCurrency(order.total)}</div>
				</div>
			</div>
		</div>
	`)
}
