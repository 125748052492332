import React, {useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { debounce } from 'lodash'
import { getOrders, filterOrder } from '../../actions/orderActions'
import FormDropdown from '../utils/FormDropdown'

const OrderFilter = ({getOrders, filterOrder }) => {

	const [filters, setFilters] = useState({
		f_keywords: '',
		f_status: '',
	})
	const handler = useCallback(debounce(()=>{
		getOrders()
	}, 300), []);

	useEffect(() => {
		
		return () => {
			setFilters({})
			filterOrder({})
		}

		// eslint-disable-next-line
	}, [])

	const onChange = (e) =>  {
		var tmp = {
			...filters,
			[e.target.name]: e.target.value
		}

		setFilters(tmp);
		filterOrder(tmp);
		handler();
	}

	const clearFilter = () => {
		setFilters({})
		filterOrder({})
		handler();
	}

	return (
		<div className="row margin0">
			<div className="input-field col s4 ">
				<i className="material-icons prefix right-align">search</i>
				<input id="f_keywords" type="text" name="f_keywords" className="validate" onChange={onChange} />
				<label htmlFor="f_keywords">Search Keywords</label>
			</div>
			<div className="input-field col s3 ">
				<FormDropdown name="f_status" remoteParam={{table:"options", whereCol: 'category_id', whereVal:1}} className="validate" onChange={onChange} value={filters.f_status} />
				<label>Status</label>
			</div>
			<div className="input-field col s3 ">
				<FormDropdown name="f_user_id" remoteParam={{table:"users"}} 
					className="validate"
					onChange={onChange} value={filters.f_user_id} />
				<label>Referral</label>
			</div>
			
			<div className="input-field col s2" style={{paddingTop:"10px"}}>
				<button onClick={()=>clearFilter()} className="btn white grey-text">Clear</button>
			</div>
		</div>
	)
}


export default connect(null, {getOrders, filterOrder})(OrderFilter)
