import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import { saveContact } from '../../actions/contactActions'

import {className} from '../../helpers/Common'

import M from 'materialize-css/dist/js/materialize.min.js';

const ContactForm = ({saveContact, contactR:{error_array, current}}) => {

	const contactFields = {
		name: '',
		description: '',
		organization: '',
		phone_work: '',
		phone_private: '',
	}

	const [contact, setContact] = useState(contactFields)
	const {name, description, organization, phone_work, phone_private} = contact;

	useEffect(() => {

		M.AutoInit(document.getElementById("contactFormWrapper"));
		
	}, [])

	useEffect(() => {

		if(current){
			var contactForm = document.getElementById("contactForm");
			var contactFormModal =M.Modal.getInstance(contactForm);

			current.row.phone_work = current.row.phone_work ? (current.row.phone_work).join(', ') : '';
			current.row.phone_private = current.row.phone_work ? (current.row.phone_private).join(', ') : '';

			setContact(current.row);	
			contactFormModal.open();

			setTimeout(()=>{
				M.updateTextFields();
			},0)
		} else {	
			setContact(contactFields);	
		}

		// eslint-disable-next-line
	}, [current])

	const onChange = (e) => {
		setContact({
			...contact,
			[e.target.name]: e.target.value
		})
	}

	const onSubmit = (e) => {
		e.preventDefault();
		saveContact(contact);
		
		if(Object.keys(error_array).length === 0){
			setContact(contactFields);
			setTimeout(() => {
				M.updateTextFields();
			}, 0);
		}
	}

	return (
		<div id="contactFormWrapper">
			<div id="contactForm" className="modal modal-sm">
		
				<form onSubmit={onSubmit} className="col s12">
				<div className="modal-content">
					<h4 className="indigo-text darken-3 font-semibold">
					<i className="fa fa-id-card"></i> Contacts Details
					</h4>
				
					<br/>
					<div className="input-field ">
						<input id="frm_prod_name" name="name" type="text" className={className('name', error_array)} value={name} onChange={onChange} />
						<label htmlFor="frm_prod_name">Name</label>
						<span className="helper-text" data-error={error_array.name}></span>
					</div>

					<div className="input-field ">
						<input id="frm_cntct_organization" name="organization" type="text" 
						className={className('organization', error_array)} value={organization} 
						onChange={onChange} />
						<label htmlFor="frm_cntct_organization">Company</label>
						<span className="helper-text" data-error={error_array.organization}></span>
					</div>

					<div className="input-field ">
						<textarea id="frm_prod_desc" name="description" 
						className={className('description', error_array, 'materialize-textarea')} 
						value={description} onChange={onChange} ></textarea>
						<label htmlFor="frm_prod_desc">Description</label>
					</div>

					<div className="row margin0">
						<div className="input-field col s12">
							<i className="material-icons prefix">phone</i>
							<input id="frm_cntct_phone_work" name="phone_work" type="text" 
							className={className('phone_work', error_array)}
							value={phone_work} onChange={onChange} />
							<label htmlFor="frm_cntct_phone_work">Phone (Work)</label>
							<span className="helper-text" data-error={error_array.phone_work}>Separate by comma</span>
						</div>
					</div>

					<div className="row margin0">
						<div className="input-field col s12">
							<i className="material-icons prefix">phone</i>
							<input id="frm_cntct_phone_private" name="phone_private" type="text" 
							className={className('phone_private', error_array)}
							value={phone_private} onChange={onChange} />
							<label htmlFor="frm_cntct_phone_private">Phone (Personal)</label>
							<span className="helper-text" data-error={error_array.phone_private}>Separate by comma</span>
						</div>
					</div>
				</div>
				<div className="modal-footer">
					<a href="#!" className="modal-close waves-effect waves-green btn-flat">Cancel</a>
					<button type="submit" className=" waves-effect waves-blue btn" >Save</button>
				</div>
				</form>
			</div>
		</div>
	)
}

ContactForm.propTypes = {
	saveContact: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
	contactR: state.contactR
})

export default connect(mapStateToProps, {saveContact})(ContactForm)
