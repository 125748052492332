import React, {useEffect, Fragment} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import {logout} from '../../actions/globalActions'

import M from 'materialize-css/dist/js/materialize.min.js';

const FloatingMenu = ({logout, global: {currentUser}}) => {

	useEffect(() => {
		
		M.AutoInit(document.getElementById('floatingMenu'));

		return () => {
			
		}
	}, [currentUser])

	if( ! localStorage.getItem('userId'))
	{
		return null;
	}

	return (
		<div id="floatingMenu" className="hide-on-med-and-down">
			<div className="fixed-action-btn">
				<Link to="/orders/form" className="waves-effect waves-light btn-floating btn-large indigo tooltipped" data-position="left" data-tooltip="+ Add New Order" ><i className="large material-icons">assignment</i></Link>
				<ul>
					<li>
						<button onClick={() => logout() } className="btn-floating red tooltipped" data-position="left" data-tooltip="Logout"><i className="fa fa-sign-out-alt"></i></button>
					</li>
					{currentUser.group_id === '1' && 
						<Fragment>
						<li>
							<Link to="/contacts" className="btn-floating orange tooltipped" data-position="left" data-tooltip="Manage Contacts"><i className="fa fa-id-card"></i></Link>
						</li>
						<li>
							<Link to="/users" className="btn-floating amber tooltipped" data-position="left" data-tooltip="Manage Users"><i className="fa fa-users-cog"></i></Link>
						</li>
						</Fragment>
					}
					<li>
						<a className="btn-floating teal tooltipped modal-trigger" href="#accountModal" data-position="left" data-tooltip="My Account"><i className="fa fa-user-circle"></i></a>
					</li>
				</ul>
			</div>
		</div>
	)
}

const mapStateToProps = state => ({
	global: state.global
})

export default connect(mapStateToProps, {logout})(FloatingMenu)
