import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {monthlyReport} from '../../actions/reportActions'
import {formatCurrency} from '../../helpers/Common'
import moment from 'moment'

import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const Monthly = ({monthlyReport, reportR: {monthly}}) => {

    useEffect(() => {

        if(monthly === null)
        {
            monthlyReport()
        }

        return () => {
         
        }

    }, [monthly])

    if(monthly !== null)
    {
        let dateArray = [];
        let orderArray = [];
        let amountArray = [];
        
        monthly.orders.map(row => {
            dateArray.push(moment(row.date).format('MMM YYYY'))
            orderArray.push(parseInt(row.total_orders))
            amountArray.push(parseFloat(row.total_amount))
        })

        const options = {
            chart: {
                zoomType: 'xy'
            },
            title: {
                text: 'Monthly Orders Report'
            },
            subtitle: {
                //text: 'Source: WorldClimate.com'
            },
            xAxis: [{
                categories: dateArray, //['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                crosshair: true
            }],
            yAxis: [{ // Primary yAxis
                labels: {
                    format: '{value}',
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                },
                title: {
                    text: 'Total Orders',
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                }
            }, 
            // { // Secondary yAxis
            //     title: {
            //         text: 'Total Amount',
            //         style: {
            //             color: Highcharts.getOptions().colors[0]
            //         }
            //     },
            //     labels: {
            //         format: '{value}',
            //         formatter: function() {return formatCurrency(this.value)},
            //         style: {
            //             color: Highcharts.getOptions().colors[0]
            //         }
            //     },
            //     opposite: true
            // }
            ],
            tooltip: {
                shared: true
            },
            legend: {
                layout: 'vertical',
                align: 'left',
                x: 120,
                verticalAlign: 'top',
                y: 100,
                floating: true,
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || // theme
                    'rgba(255,255,255,0.25)'
            },
            series: [{
                name: 'Total Order',
                type: 'spline',
                data: orderArray,
                tooltip: {
                    //valueSuffix: '°C'
                }
            }
            // ,{
            //     name: 'Total Amount',
            //     type: 'column',
            //     yAxis: 1,
            //     data: amountArray,
            //     tooltip: {
            //         valuePrefix: 'Php '
            //     }
        
            // }
            ]
        }
    

        return (
            <div className="card white" style={{marginTop:0}}>
               
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
        )
    }

    return <div></div>
}

const mapStateToProps = state => ({
    reportR: state.reportR
})

export default connect(mapStateToProps, {monthlyReport})(Monthly)
