import React, { useState, useEffect} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {submitLogin} from '../../actions/globalActions'
import {Redirect } from 'react-router-dom';

import M from 'materialize-css/dist/js/materialize.min.js';

const LoginForm = ({submitLogin, global: {logged_in, error_array}}) => {

	useEffect(()=>{
		document.body.classList.add('indigo')
		document.body.classList.remove('blue-grey', 'lighten-5')
		
		return () => {
			document.body.classList.remove('indigo')
			document.body.classList.add('blue-grey', 'lighten-5')
		}
	}, [logged_in])


	const [login, setLogin] = useState({
		username:'',
		password:''
	})

	const onChange = (e) => {
		setLogin({
			...login, 
			[e.target.name]:e.target.value
		})
	};

	const submit = (e) => {
		e.preventDefault();
		submitLogin(login);
	}

	if(localStorage.getItem('token')){
		M.toast({html: 'Login Success'})
		return <Redirect to="/" />;
	}
	
	return (
		<div className="container">
			<div className="row">
				<div className="col s12 m6 offset-m3 l4 offset-l4 ">
					<div className="z-depth-4" style={{ "marginTop": "30%" }} >

						<div className="card">
							<div className="card-content ">
								
								<form onSubmit={submit}>
									<h3 className="indigo-text center-align">SIGN IN</h3>
									<div className="input-field">
										<i className="material-icons prefix indigo-text">account_circle</i>
										<input id="icon_prefix" name="username" type="text" className={ 'validate' + (error_array.username ? ' invalid' : '')}  onChange={onChange} />
										<label htmlFor="icon_prefix">Username</label>
										<span className="helper-text" data-error={error_array.username}></span>
									</div>

									<div className="input-field">
										<i className="material-icons prefix indigo-text">lock</i>
										<input id="icon_telephone" name="password" type="password" className={ 'validate' + (error_array.password ? ' invalid' : '')} onChange={onChange} />
										<label htmlFor="icon_telephone">Password</label>
										<span className="helper-text" data-error={error_array.password}></span>
									</div>
									<br/>
									<div className="center-align">
										<button className="btn btn-large waves-effect waves-light amber" type="submit" name="action">LOGIN
											<i className="material-icons right">lock_open</i>
										</button>

									</div>
									
									<br/>
									
									<br/>
								</form>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	)
}

LoginForm.propTypes = {
	submitLogin: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
	global: state.global,
});

export default connect(mapStateToProps, {submitLogin})(LoginForm)
