import React, {useEffect} from 'react'
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import { useHistory } from 'react-router-dom';
import {getUsers, editUser, clearCurrent, deleteUser} from '../../actions/userActions'
import {verify} from '../../helpers/Common'

import UserForm from './UserForm'
import DisplayingRecords from '../utils/DisplayingRecords'
import Pagination from '../utils/Pagination'

import moment from 'moment';
import M from 'materialize-css/dist/js/materialize.min.js';

const Users = ({getUsers,editUser, clearCurrent,deleteUser, global:{currentUser}, userR: {users, pagination}}) => {

	
	const history = useHistory();
	
	useEffect(() => {
		
		getUsers();

		// eslint-disable-next-line
	}, [])

	if(currentUser.group_id === '2')
	{
		M.toast({html: 'Permission denied.', 'classes':'red'});
		history.push('/');
		return '';
	}
	

	return (
		<div className="container col">
			<UserForm />
			<div className="row">
				<div className="col s6 m6">
					<h5 className="indigo-text text-darken-4 font-semibold">
						<i className="fa fa-users-cog" ></i> Users
					</h5>
				</div>
				<div className="col s6 m6">
					<br/>
					<div className="right">
					<a href="#userForm" className="waves-effect waves-light btn modal-trigger" onClick={()=> clearCurrent()}><i className="material-icons left">add</i> New User</a>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col s12 m12">
					<div className="right-align">
						<DisplayingRecords data={pagination} />
					</div>
					<div className="card white">
						<div>
							<table className="striped">
								<thead>
									<tr className="teal lighten-1 white-text">
										<th>ID</th>
										<th>Username</th>
										<th>Name</th>
										<th>Role</th>
										<th className="center-align">Status</th>
										<th className="center-align">Last Login</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{ users === null ? <tr></tr> : users.result.map(user => 
									<tr key={`user`+user.id} >
										<td>{user.id}</td>
										<td>{user.username}</td>
										<td>{user.name}</td>
										<td>{user.group_name}</td>
										<td className="font-semibold center-align">
											{user.active === '1' ? <span className="green-text">Active</span> : <span className="grey-text">Inactive</span>}
										</td>
										<td className="center-align">{moment(user.last_login).format('lll')}</td>
										<td className="right">
											<a href="#userForm" className="waves-effect waves-light btn-small amber tooltipped" data-position="top" data-tooltip="Edit" 
											onClick={()=>editUser(user.id)} ><i className="material-icons" >edit</i></a> 
											&nbsp;
											<button className="waves-effect waves-light btn-small red lighten-1 tooltipped" data-position="top" data-tooltip="Delete" onClick={()=>verify('Are you sure you want to delete this?', ()=>deleteUser(user.id) ) }><i className="material-icons"  >delete</i></button> 
										</td>
									</tr>)}
								</tbody>
							</table>
						</div>
						<div className="card-footer">
							<div className="row margin0">
								<div className="col s6 offset-s6">
									<div className="right">
										<Pagination data={pagination} paginate={(n)=>getUsers(n)} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

Users.propTypes = {
	getUsers: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
	global:state.global,
	userR: state.userR
})

export default connect(mapStateToProps, {getUsers, editUser, clearCurrent, deleteUser})(Users)
