import React, {useState, useEffect, useRef} from 'react'
import { connect } from 'react-redux'
import { useHistory, Link } from 'react-router-dom';
import { getOrderEvents } from '../../actions/dashboardActions'

import {CalendarPopover} from './CalendarPopover'
import RecentOrders from './RecentOrders'
import Stats from './Stats'

import {Calendar} from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import interaction from '@fullcalendar/interaction'


import M from 'materialize-css/dist/js/materialize.min.js';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';

const Dashboard = ({getOrderEvents, dashboardR:{orderEvents}, global:{currentUser,socket}}) => {

	const calendarRef = useRef();
	const history = useHistory();

	const [calendar, setCalendar] = useState();
	const [calFilter, setCalfilter] = useState({
		filtering:0,
		f_order_date: 1,
		f_target_date: 1
	});

	const eventSource = {
		id: 'oe',
		events: (param, successCallback) => {
			var req = getOrderEvents({...param, ...calFilter});
			req.then((data) => {
				successCallback(data.orders)
			})
		},
	};

	useEffect(() => {
	
		var tmp = new Calendar(calendarRef.current, {
			plugins: [ dayGridPlugin, interaction ],
			height: 'auto',
			eventLimit: true,
			header: {
				left: 'dayGridMonth, dayGridWeek',
				center: 'title',
				right: 'prev,next today',
			},
			eventSources: [eventSource],
			eventRender: function(info) {
			
				M.Tooltip.init(info.el, {
					position: 'right',
					html: CalendarPopover(info.event.extendedProps.order)
				});
			},
			eventClick: function(info) {
				var instance = M.Tooltip.init(info.el);
				instance.close();
				history.push('/orders/edit/'+info.event.extendedProps.order.id)
			},
			views: {
				month: {
					eventLimit:5,
				},
				week: {
					eventLimit:50,
				},

			}
		});
		tmp.render();
		setCalendar(tmp);


		// eslint-disable-next-line 
	}, [])

	useEffect(() => {
		
		if(calendar){
			socket.on('Orders Refresh', (param) => {
				if(param.userId !== currentUser.userId){
					reloadCalendar()
				}
			})
		}
		
		return () => {
			socket.off('Orders Refresh')
		}

		// eslint-disable-next-line 
	}, [calendar])
	
	useEffect(() => {
		
		if(calFilter.filtering)
		{
			reloadCalendar();
		}

		// eslint-disable-next-line 
	}, [calFilter])

	const filterCalendar = (e) => {
	
		setCalfilter({
			...calFilter, 
			filtering: 1,
			[e.target.name]:(e.target.checked ? 1:0)
		});		
	}

	const reloadCalendar = () => {
		var EventSource = calendar.getEventSourceById('oe')
		EventSource.remove();
	
		calendar.addEventSource(eventSource)
		calendar.refetchEvents();
	}

	return (
		<div className="container col">
		
			<div className="row" style={{marginBottom:0}}>
				
				<div className="col s12 m8 l9 push-m3 push-l3">
					<br/>
					<div className="right-align">
						<Link to="/orders/form" className="waves-effect waves-light btn "><i className="material-icons left">add</i> New Order</Link>
					</div>
					
					<Stats />
					<div className="white padding15">
						<div ref={calendarRef}></div>
					
						<div className="row" style={{marginTop:'8px',marginBottom:'8px'}}>
							<div className="col">
								<div className="switch">
									<label>
										Show Orders
										<input type="checkbox" name="f_order_date" 
										checked={calFilter.f_order_date} 
										onChange={filterCalendar} />
										<span className="lever"></span>
									</label>
								</div>

							</div>
							<div className="col">

								<div className="switch">
									<label>
										Show Deliveries
										<input type="checkbox" name="f_target_date" 
										checked={calFilter.f_target_date}
										onChange={filterCalendar}  />
										<span className="lever"></span>
									</label>
								</div>
							</div>
						</div>
					</div>

				</div>
				
				<div className="col s12 m4 l3 pull-m9 pull-l9" style={{paddingRight:0}}>
					<h5 className="indigo-text text-darken-4 font-semibold">
						<i className="fa fa-fire" ></i> Recent Orders
					</h5>
					
					<RecentOrders />
				</div>
			</div>
			<br/>
		</div>
	)
}

const mapStateToProps = state => ({
	global: state.global,
	dashboardR: state.dashboardR
})

export default connect(mapStateToProps, {getOrderEvents})(Dashboard)
