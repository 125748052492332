import { 
	GET_PRODUCTS,
	SAVE_PRODUCT,
	PRODUCT_ERROR,
	VIEW_PRODUCT,
	CLEAR_PRODUCT
} from '../actions/types';

const initialState = {
	products: null,
	current: null,
	loading: false,
	pagination: null,
	error: null,
	error_array: {},
}

export default (state = initialState, action) => {
	switch(action.type){
		case GET_PRODUCTS:
			return {
				...state,
				products: action.payload,
				pagination: action.payload.pagination,
				loading:false
			}
		case PRODUCT_ERROR:
			return {
				...state,
				error: action.payload,
				loading: false
			}
		case VIEW_PRODUCT:
			return {
				...state,
				current: action.payload
			}
		case SAVE_PRODUCT:
			return {
				...state,
				error_array: (typeof action.payload.error_array !== 'undefined' ? action.payload.error_array : {}),
				loading: false,
			}
		case CLEAR_PRODUCT:
			return {
				...state,
				current: null,
				error: null,
				error_array: {}
			}		
		default:
			return state;
	}

}