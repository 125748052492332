import React from 'react'

const Customers = () => {
	return (
		<div>
			zzz
		</div>
	)
}

export default Customers
