import React, {useState, useEffect, Fragment} from 'react';
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import {loadUser, logout} from '../../actions/globalActions'
import FloatingMenu from './FloatingMenu';
import SideMenu from './SideMenu';

const Header = ({loadUser, logout, global:{currentUser}}) => {

	const [currentUrl, setCurrentUrl] = useState('');

	useEffect(() => {
		loadUser();
		setCurrentUrl(window.location.pathname)

		// eslint-disable-next-line
	}, [])
	
	const setCurrentPage = () => {
		setTimeout(() => {
			setCurrentUrl(window.location.pathname)
		}, 0);
	}

	return (
		<Fragment>
			<nav className="indigo darken-4">
				<div className="nav-wrapper container">
				<Link to="/" className="brand-logo" onClick={setCurrentPage}><i className="material-icons">home</i>OMS</Link>
					<div className="hide-on-med-and-down">
						
						<ul className="right">
							<li className={currentUrl === '/' ? 'active' :''}>
								<Link to="/" onClick={setCurrentPage}><i className="material-icons left">dashboard</i> Dashboard</Link>
							</li>
							<li className={currentUrl === '/orders' ? 'active' :''}>
								<Link to="/orders" onClick={setCurrentPage}><i className="material-icons left">assignment</i> Orders</Link>
							</li>
							
							<li className={currentUrl === '/products' ? 'active' :''}>
								<Link to="/products" onClick={setCurrentPage}><i className="material-icons left">all_inbox</i> Products</Link>
							</li>
							
							<li className={currentUrl === '/reports' ? 'active' :''}>
								<Link to="/reports" onClick={setCurrentPage}><i className="material-icons left">insert_chart</i> Reports</Link>
							</li>
						</ul>
					</div>
					<a href="#!" data-target="slide-out" className="sidenav-trigger right"><i className="material-icons">menu</i></a>
				</div>
			
			</nav>
			<SideMenu />
			<FloatingMenu />
		</Fragment>
	)
}


Header.propTypes = {
	loadUser: PropTypes.func.isRequired,
	logout: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
	global: state.global,
});


export default connect(mapStateToProps, {loadUser, logout})(Header)

