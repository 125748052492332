import React, {useState, useEffect, Fragment} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import {logout, loadUser} from '../../actions/globalActions'


import M from 'materialize-css/dist/js/materialize.min.js';

const SideMenu = ({loadUser, logout, global: {currentUser}}) => {

	const [currentUrl, setCurrentUrl] = useState('');

	useEffect(() => {
		loadUser();
		setCurrentUrl(window.location.pathname)

		// eslint-disable-next-line
	}, [])
	
	const setCurrentPage = () => {
		var sidenav = M.Sidenav.getInstance(document.getElementById('slide-out') );
		
		setTimeout(() => {
			sidenav.close();
			setCurrentUrl(window.location.pathname)
		}, 0);
	}

	return (
		<ul id="slide-out" className="sidenav">
			<li className={currentUrl === '/' ? 'active' :''}>
				<Link to="/" onClick={setCurrentPage}><i className="material-icons left">dashboard</i> Dashboard</Link>
			</li>
			<li className={currentUrl === '/orders' ? 'active' :''}>
				<Link to="/orders" onClick={setCurrentPage}><i className="material-icons left">assignment</i> Orders</Link>
			</li>
			
			<li className={currentUrl === '/products' ? 'active' :''}>
				<Link to="/products" onClick={setCurrentPage}><i className="material-icons left">all_inbox</i> Products</Link>
			</li>
			
			<li className={currentUrl === '/reports' ? 'active' :''}>
				<Link to="/reports" onClick={setCurrentPage}><i className="material-icons left">insert_chart</i> Reports</Link>
			</li>

			<li><div className="divider"></div></li>

			
			{currentUser.group_id === '1' && 
				<Fragment>
				<li>
					<Link to="/contacts" onClick={setCurrentPage} className="tooltipped" data-position="left" data-tooltip="Contacts"><i className="fa fa-id-card"></i> Contacts</Link>
				</li>
				<li>
					<Link to="/users" onClick={setCurrentPage} className="tooltipped" data-position="left" data-tooltip="Manage Users"><i className="fa fa-users-cog"></i> Manage Users</Link>
				</li>
				</Fragment>
			}
			<li>
				<a className="tooltipped modal-trigger" href="#accountModal" data-position="left" data-tooltip="My Account"><i className="fa fa-user-circle"></i> My Account</a>
			</li>
			<li>
				<a href="#!" onClick={() => logout() } className="tooltipped" data-position="left" data-tooltip="Logout"><i className="fa fa-sign-out-alt"></i> Logout</a>
			</li>
		</ul>
	)
}



SideMenu.propTypes = {
	loadUser: PropTypes.func.isRequired,
	logout: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
	global: state.global
})

export default connect(mapStateToProps, {logout, loadUser})(SideMenu)
