import React, {useEffect, Fragment} from 'react'
import {connect} from 'react-redux'
import { getDropdown } from '../../actions/globalActions';
import M from 'materialize-css/dist/js/materialize.min.js';	

const FormDropdown = ({getDropdown, global:{dropdown}, ...param}) => {

	const {name, onChange, remoteParam, relation } = param;

	let options = param.options;

	const selectProps = {
		id: param.id || `frmDD_${name}`,
		value: param.value || ''
	}

	if(typeof param.defaultValue !== 'undefined')
	{
		selectProps.defaultValue = param.defaultValue;
	}

	useEffect(() => {
		if(typeof remoteParam !== 'undefined'){
			getDropdown(remoteParam);	
		}

		// eslint-disable-next-line
	}, [])

	// Populate Options
	if(typeof remoteParam !== 'undefined'){
				
		// generate key
		let {table, whereVal} = remoteParam;
		remoteParam['key'] = table === 'options' ? 'options'+whereVal : table;

		if(dropdown[remoteParam.key]){
			options = dropdown[remoteParam.key];

			setTimeout(function(){
				var elems = document.querySelectorAll('select');
				M.FormSelect.init(elems);
			},0)
		}
	}
	const onSelect = (e) => {

		onChange(e);

		// get the options for the relative dropdown
		if(typeof relation !== 'undefined'){
			let {table, whereVal} = relation;
			relation['key'] = table === 'options' ? 'options'+whereVal : table;
			relation['whereVal'] = e.target.value;
			getDropdown(relation, true);
		}
	}


	if(typeof options !== 'undefined')
	{
		return <Fragment>
			<select name={name} onChange={onSelect} {...selectProps} >
				<option value="" disabled>Select an option</option>
				{options.map(opt => 
					<option key={opt.val} value={opt.val} >{opt.text} </option>
				)}
			</select>
		</Fragment>
	}
	
	// default return
	return <Fragment>
		<select name={name} onChange={onChange}{...selectProps} >
			<option value="" disabled>Select an option</option>
		</select>
	</Fragment>
}


const mapStateToProps = state => ({
	global: state.global,
});

export default connect(mapStateToProps, {getDropdown })(FormDropdown);
