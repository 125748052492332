import React, {useEffect} from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Pagination from '../utils/Pagination';
import DisplayingRecords from '../utils/DisplayingRecords';
import {formatCurrency, verify } from '../../helpers/Common';
import ProductForm from './ProductForm';
import { getProducts, editProduct, deleteProduct,clearCurrent } from '../../actions/productActions';

const Products = ({ getProducts, editProduct, deleteProduct,clearCurrent, productR: {products, pagination, loading}, global:{currentUser}}) => {
	
	useEffect(() => {
		getProducts();
		
		return () => {
		 	clearCurrent();
		}
	
		// eslint-disable-next-line
	}, []);

	
	return (
		<div className="container col">
			<ProductForm />
			<div className="row">
				<div className="col s6 m6">
					<h5 className="indigo-text text-darken-4 font-semibold">
					<i className="material-icons small middle-align" >all_inbox</i> Products
					</h5>
				</div>
				<div className="col s6 m6">
					<br/>
					{currentUser.group_id === '1' && <div className="right">
					<a href="#productForm" className="waves-effect waves-light btn modal-trigger" onClick={()=> clearCurrent()}><i className="material-icons left">add</i> Product</a>
					</div>
					}
				</div>
			</div>
			
			<div className="row">
				<div className="col s12 m12">
					<div className="right-align">
						<DisplayingRecords data={pagination} />
					</div>
					<div className="card white">
						<div>
							<table className="striped">
								<thead>
									<tr className="teal lighten-1 white-text">
										<th>ID</th>
										<th>Name</th>
										<th>Description</th>
										<th>Category</th>
										<th>Price</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{ products === null ? <tr></tr> : products.result.map(product => 
									<tr key={`product`+product.id} >
										<td>{product.id}</td>
										<td>{product.name}</td>
										<td>{product.description}</td>
										<td>{product.category}</td>
										<td>{formatCurrency(product.price)}</td>
										<td className="right">
										{currentUser.group_id === '1' && 
											<div>
												<a href="#productForm" className="waves-effect waves-light btn-small amber tooltipped" data-position="top" data-tooltip="Edit" onClick={()=>editProduct(product.id)} ><i className="material-icons" >edit</i></a> 
												&nbsp;
												<button className="waves-effect waves-light btn-small red lighten-1 tooltipped" data-position="top" data-tooltip="Delete" onClick={()=>verify('Are you sure you want to delete this?', ()=>deleteProduct(product.id)) }><i className="material-icons"  >delete</i></button> 
											</div>
										}
										</td>
									</tr>)}
								</tbody>
							</table>
						</div>
						<div className="card-footer">
							<div className="row margin0">
								<div className="col s6 offset-s6">
									<div className="right">
										<Pagination data={pagination} paginate={(n)=>getProducts(n)} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

Products.propTypes = {
	productR: PropTypes.object.isRequired,
	getProducts: PropTypes.func.isRequired,
	deleteProduct: PropTypes.func.isRequired,
	editProduct: PropTypes.func.isRequired,
	clearCurrent: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
	global: state.global,
	productR: state.productR,
});

export default connect(mapStateToProps, { getProducts, editProduct, deleteProduct, clearCurrent})(Products);
