import {
	GET_CONTACTS,
	SAVE_CONTACT,
	VIEW_CONTACT,
	CLEAR_CONTACT
} from '../actions/types'


const initialState = {
	contacts: null,
	current: null,
	pagination: null,
	loading:false,
	error: null,
	error_array: {},
}

export default (state = initialState, action) => {
	switch(action.type){
		case GET_CONTACTS:
			return {
				...state,
				contacts: action.payload,
				pagination: action.payload.pagination,
				loading:false
			}
		case SAVE_CONTACT:
			return {
				...state,
				error_array: (typeof action.payload.error_array !== 'undefined' ? action.payload.error_array : {}),
				loading: false,
			}
		case VIEW_CONTACT:
			return {
				...state,
				current: action.payload
			}
		case CLEAR_CONTACT:
			return {
				...state,
				current: null,
				error: null,
				error_array: {}
			}	
		default:
			return state

	}
}
