import React from 'react'

const Pagination = ({ data, paginate}) => {


	
	if(data && data.links !== '')
	{
		return <ul className="pagination">
			{ data.links.map((row, key) =>
			<li key={key} className={(row.current_page !== undefined ? 'active' : 'waves-effect')}>
				<a href="#!" onClick={()=>paginate(row.page)}>{row.text}</a>
			</li> 
			)}
		</ul>
		
	}

	return '';
}

export default Pagination
